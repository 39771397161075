var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['bundle', _vm.bundleToDisplay.display]},[_c('SubscriptionCard',{attrs:{"bundle":_vm.bundleToDisplay},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.bundleToDisplay.display))]},proxy:true},{key:"price",fn:function(){return [_c('span',{class:['interval', _vm.bundleToDisplay.display]},[(_vm.checkIsAnnuallySubscription(_vm.bundleToDisplay))?_c('span',[_vm._v("/year")]):_c('span',[_vm._v("/mo")])]),_c('span',{staticClass:"bundle__price__additional-text"},[_vm._v("Before tax")]),(_vm.isSelectingAnnuallyPayment)?_c('span',{staticClass:"bundle__price__discount"},[_vm._v("20% discount on total price")]):_vm._e()]},proxy:true},{key:"region",fn:function(){return [(Number(_vm.bundleToDisplay.regionCount) > 1)?_c('span',[_vm._v("Regions")]):_c('span',[_vm._v("Region")])]},proxy:true},{key:"additional",fn:function(){return [(_vm.bundles.length > 1)?_c('div',{staticClass:"bundle__select-payment"},[_c('div',{staticClass:"container"},[_c('div',{class:[
              'bundle',
              {
                active: _vm.isSelectingMonthlyPayment
              }
            ],on:{"click":function($event){_vm.selectedBundleIndex = 0}}},[_c('div',{staticClass:"price"},[_vm._v("Pay Monthly")])]),_c('div',{staticStyle:{"width":"20px","padding":"0"}}),_c('div',{class:[
              'bundle',
              {
                active: _vm.isSelectingAnnuallyPayment
              }
            ],on:{"click":function($event){_vm.selectedBundleIndex = 1}}},[_c('div',{staticClass:"price"},[_vm._v("Pay Annually")]),_c('div',{staticClass:"best-value"},[_vm._v("Best Value")])]),_c('div',{class:[
              'selected',
              {
                'selected--left': _vm.isSelectingMonthlyPayment,
                'selected--right': _vm.isSelectingAnnuallyPayment
              }
            ]})])]):_vm._e(),_c('div',{staticClass:"bundle__additional-text"},[_vm._v(" Additional prices apply for the following countries: China, India, Japan, UK and US ex-OTC ")])]},proxy:true},{key:"footer",fn:function(){return [_c('k-button',{class:['bundle__button', _vm.bundleToDisplay.display],attrs:{"fill-mode":"outline"},on:{"click":function($event){return _vm.$emit('handleGetItNowClick', _vm.bundleToDisplay)}}},[_vm._v(" Get it now ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }