<template>
  <dropdown
    :valid="valid"
    :id="id"
    :value="value"
    :name="name"
    :data-items="data"
    :data-item-key="dataItemKey"
    :text-field="textField"
    :placeholder="placeholder"
    :disabled="disabled"
    @change="handleChange"
    @blur="handleBlur"
    @focus="handleFocus"
    @open="handleOpen"
    @close="handleClose"
  />
</template>
<script>
import { DropDownListVue2 } from '@progress/kendo-vue-dropdowns';
export default {
  props: {
    textField: String,
    dataItemKey: String,
    data: Array,
    name: String,
    placeholder: String,
    touched: Boolean,
    validationMessage: String,
    hint: String,
    id: String,
    valid: Boolean,
    disabled: Boolean,
    value: [
      String,
      Number,
      {
        type: Object,
        default: function () {
          return {};
        }
      }
    ]
  },
  components: {
    dropdown: DropDownListVue2
  },
  emits: {
    change: null,
    blur: null,
    focus: null
  },
  computed: {
    showValidationMessage() {
      return this.$props.touched && this.$props.validationMessage;
    },
    showHint() {
      return !this.showValidationMessage && this.$props.hint;
    },
    hintId() {
      return this.showHint ? `${this.$props.id}_hint` : '';
    },
    errorId() {
      return this.showValidationMessage ? `${this.$props.id}_error` : '';
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e);
    },
    handleBlur(e) {
      this.$emit('blur', e);
    },
    handleFocus(e) {
      this.$emit('focus', e);
    },
    handleOpen(e) {
      this.$emit('open', e);
    },
    handleClose(e) {
      this.$emit('close', e);
    }
  }
};
</script>
