<template>
  <div class="summary">
    <h5>Summary:</h5>

    <table
      class="summary_table"
      style="border-collapse: collapse; border: none"
    >
      <tbody>
        <subscription-product-summary
          :itemName="proposedPlan.display + ' Plan'"
          :proposedProduct="proposedPlan"
          :currentProduct="currentPlan"
          className="row--even"
          :isBundle="true"
        />

        <template v-for="(addon, index) in addons">
          <subscription-product-summary
            v-if="addon.quantity > 0"
            :key="addon.product"
            :itemName="addon.display"
            :proposedProduct="addon"
            :currentProduct="
              currentPlan.addons.find(
                ({ product }) => product === addon.product
              )
            "
            :className="index % 2 === 0 ? 'row--odd' : 'row--even'"
          />
        </template>
      </tbody>
    </table>

    <div>
      <div class="total-price-box">
        <div class="total-text">Total Price</div>
        <div>{{ total | displayPrice(false, currency) }}</div>
      </div>

      <template v-if="discount > 0">
        <div class="total-price-box">
          <div class="total-text">
            Discount
            <div
              class="discount-detail"
              @click="isDiscountDetailOpen = !isDiscountDetailOpen"
            >
              <span
                class="k-icon k-panelbar-toggle k-panelbar-expand k-i-arrow-chevron-down"
              />
            </div>
          </div>
          <div class="price saved-value">
            -{{ discount | displayPrice(false, currency) }}
            <div v-if="discountPercent > 0">
              Saved {{ discountPercent }}% (Seats x
              {{ Number(extraSeatCount) + 1 }})
            </div>
            <div v-if="isAnnualSubscription">
              Saved EXTRA {{ ANNUAL_SUBSCRIPTION_DISCOUNT_PERCENTS }}% (Annual
              Subscription)
            </div>
          </div>
        </div>
        <div v-if="isDiscountDetailOpen">
          <discount-detail />
        </div>
      </template>

      <hr />

      <div class="total-price-box" v-if="proposedPlan.subscriptionTotal > 0">
        <div class="total-text" v-if="isUserUpdatedSubscription">
          Total Cost of Updated Subscription
        </div>
        <div class="total-text" v-else>Total Cost of Subscription</div>
        <div>
          {{ proposedPlan.subscriptionTotal | displayPrice(false, currency) }}
        </div>
      </div>

      <template
        v-if="currentPlan.subscriptionTotal > 0 && isUserUpdatedSubscription"
      >
        <div class="total-price-box">
          <div class="total-text">Previous Subscription Cost</div>
          <div>
            {{ currentPlan.subscriptionTotal | displayPrice(false, currency) }}
          </div>
        </div>
      </template>

      <template v-if="isUserUpdatedSubscription">
        <template v-if="finalPayment > 0">
          <hr />

          <div class="total-price-box">
            <div class="total-text">Cost of Additional Products</div>
            <div class="price">
              {{ finalPayment | displayPrice(false, currency) }}
            </div>
          </div>
        </template>

        <template v-else-if="proposedPlan.subscriptionTotal > 0">
          <hr />

          <div class="total-price-box">
            <div class="total-text">
              Cost of Additional Products
              <k-tooltip
                id="tooltip"
                :title="`Your product decreased after the update. According to our policies, refund is not allowed.
                  <br />Keep in mind that you can file a refund claim if an unwanted transaction
                  is made by us. <br />
                  We will work with the evidence you provided to determine if the
                  transaction is refundable according to our policies <br />${contactAdmin}`"
                :position="'left'"
              >
                <span class="k-icon k-i-info text-warning"></span>
              </k-tooltip>
            </div>
            <div class="price">
              {{
                proposedPlan.subscriptionTotal | displayPrice(false, currency)
              }}
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import SubscriptionProductSummary from '@/components/tenantAdmin/components/SubscriptionProductSummary/SubscriptionProductSummary.vue';
import DiscountDetail from '@/components/tenantAdmin/components/DiscountDetail/DiscountDetail.vue';
import { mapGetters } from 'vuex';
import { displayPrice } from '@/components/tenantAdmin/filters/displayPrice';
import {
  DISCOUNT_PERCENTS,
  ANNUAL_SUBSCRIPTION_DISCOUNT_PERCENTS
} from '@/shared';

export default {
  props: ['subscription', 'contactAdmin'],
  components: {
    SubscriptionProductSummary,
    DiscountDetail
  },
  data: function () {
    return {
      isDiscountDetailOpen: false
    };
  },
  filters: {
    displayPrice
  },
  created() {
    this.DISCOUNT_PERCENTS = DISCOUNT_PERCENTS;
    this.ANNUAL_SUBSCRIPTION_DISCOUNT_PERCENTS =
      ANNUAL_SUBSCRIPTION_DISCOUNT_PERCENTS;
  },
  watch: {
    isUserUpdatedSubscription(newValue) {
      this.$emit('userUpdatedSubscription', newValue);
    }
  },
  computed: {
    ...mapGetters('fastspring', ['extraSeatCount']),

    currentPlan() {
      return this.subscription.currentPlan;
    },
    proposedPlan() {
      return this.subscription.proposedPlan;
    },
    addons() {
      const addons = [];

      for (const addon of this.currentPlan.addons) {
        if (
          //If quantity of product equal 0 => It won't appear in proposedPlan
          !this.proposedPlan.addons.find(
            ({ product }) => product === addon.product
          )
        ) {
          addons.push({ ...addon, quantity: 0 });
        }
      }

      for (const addon of this.proposedPlan.addons) {
        if (!addons.find(({ product }) => product === addon.product)) {
          addons.push(addon);
        }
      }
      //TODO
      return addons.filter(({ product }) => {
        return product !== 'individual-seat';
      });
    },
    isAnnualSubscription() {
      return this.currentPlan.product.includes('-annual');
    },

    discountPercent() {
      let biggestDiscountPercentSeat = 0;

      for (const key in this.DISCOUNT_PERCENTS) {
        if (Number(key) > biggestDiscountPercentSeat) {
          biggestDiscountPercentSeat = Number(key);
        }
      }

      if (!this.extraSeatCount) return 0;

      if (this.extraSeatCount > biggestDiscountPercentSeat)
        return this.DISCOUNT_PERCENTS[biggestDiscountPercentSeat].value;

      return this.DISCOUNT_PERCENTS[this.extraSeatCount].value;
    },

    currency() {
      return this.proposedPlan.priceDisplay.replace(
        this.proposedPlan.priceDisplay.replace(/[^\d,.]/g, ''),
        ''
      );
    },

    total() {
      const total =
        this.proposedPlan.quantity * this.proposedPlan.price +
        this.addons.reduce((acc, cur) => acc + cur.quantity * cur.price, 0);

      return total;
    },
    discount() {
      const discount =
        this.proposedPlan.quantity * this.proposedPlan.discount +
        this.addons.reduce((acc, cur) => {
          return acc + cur.quantity * cur.discount;
        }, 0);

      return discount;
    },
    finalPayment() {
      const finalPayment =
        this.proposedPlan.proratedItemTotal +
        this.addons.reduce((acc, cur) => acc + cur.proratedItemTotal, 0);

      // If final payment small than 0. It means user reduced products and FastSpring able to refund to the user. But we do
      // not refund automatically in this case due to advice from FastSpring. So the user must pay in full and can contact us for refund manually
      return finalPayment;
    },

    isUserUpdatedSubscription() {
      if (this.currentPlan.quantity !== this.proposedPlan.quantity) return true;

      for (const proposedAddon of this.proposedPlan.addons) {
        const currentPlanAddon = this.currentPlan.addons.find(
          ({ product }) => product === proposedAddon.product
        );

        if (!currentPlanAddon) {
          return true;
        }

        if (currentPlanAddon.quantity !== proposedAddon.quantity) {
          return true;
        }
      }

      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.summary {
  &_table {
    width: 100%;
    table-layout: fixed;
    overflow-wrap: break-word;
    min-width: 500px;

    tbody {
      overflow-y: auto;
      max-height: 500px;
      overflow-x: hidden;
      display: block;
    }
  }

  > div {
    margin-bottom: 15px;
  }

  h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #ffffff;
    margin-bottom: 15px;
    text-align: left;
  }

  .total-price-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
    text-align: right;

    .total-text {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      margin-right: 12px;
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }

    .price {
      color: #34a54b;
    }
  }

  .discount-detail {
    cursor: pointer;
  }

  .saved-value.price {
    color: #dedede;
    opacity: 0.5;

    div {
      font-size: 13px;
      line-height: 14px;
      color: #34a54b;
    }
  }
}
</style>
