import Chart from "./Chart"

export default class Cluster extends Chart {

    data = 0;
    legend = "";
    description = "";
    percentile = 0;
    value = 0;

    constructor(data, legend, description, percentile, svg, svgNS, xCenter, yCenter, color, stroke, value) {

        super(svg, svgNS, xCenter, yCenter, color, stroke);
        this.data = data;
        this.legend = legend;
        this.description = description;
        this.percentile = percentile;
        this.value = value;
    }

    getData() {
        return this.data;
    }

    getLegend() {
        return this.legend;
    }

    getDescription() {
        return this.description;
    }

    getPercentile() {
        return this.percentile;
    }

    getValue() {
        return this.value;
    }

}