<template>
	<div class="layout layout--public">
		<div class="layout__body">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<icon-logo />
					</div>
				</div>
				<k-form v-if="!isSuccess" ref="form" :validator="forgotPasswordFormValidator" @submit="forgotPassword">
					<forgot-password-form />
				</k-form>
				<div v-else class="row">
					<div class="col-12 mfa-info">
						<span class="k-icon k-i-check-circle k-icon-lg text-success"></span>
					</div>
					<div class="col-12 mfa-info">
						<h1>{{ $t('SUCCESS_EMAIL_SEND_FORGOT_PASSWORD') }} <span class="text-primary">{{ email }}</span></h1>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import forgotPasswordForm from "./forgotPasswordForm";
import { forgotPasswordFormValidator } from "@/plugins/validator";
import axios from "axios";
export default {
	components: {
		forgotPasswordForm
	},
	data: function () {
		return {
			isSuccess: false,
			email: null,
			forgotPasswordFormValidator
		};
	},
	methods: {
		forgotPassword(dataItem) {
			if (this.$refs.form.isValid()) {
				axios
					.post("/auth/forgotPassword", dataItem)
					.then(() => {
						this.isSuccess = true;
						this.email = dataItem.email;
					})
					.catch(() => {
						this.$store.state.notification.show = false;
						this.isSuccess = true;
						this.email = dataItem.email;
					});
			}
		}
	}
};
</script>