import { mapGetters, mapMutations } from 'vuex';
import getSubscriptionEstimateMixin from './getSubscriptionEstimate';

export default {
  mixins: [getSubscriptionEstimateMixin],
  computed: {
    ...mapGetters('fastspring', ['selectedBundle'])
  },
  methods: {
    ...mapMutations('fastspring', [
      'SET_TOTAL_PRICE',
      'SET_TOTAL_PRICE_VALUE',
      'SET_TAX_PRICE'
    ]),
    async updateOrder({ order, userSubscriptionInformation }) {
      this.SET_TOTAL_PRICE(order['total']);
      this.SET_TAX_PRICE(order['tax']);
      this.SET_TOTAL_PRICE_VALUE(order['totalValue']);

      const estimatedSubscription = await this.getUserSubscriptionEstimate(
        userSubscriptionInformation
      );

      return estimatedSubscription;
    }
  }
};
