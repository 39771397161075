<template>
  <div class="layout layout--public">
    <div class="layout__body">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <icon-logo />
          </div>
        </div>
        <k-form ref="form" autocomplete="off">
          <k-form-element>
            <div class="row">
              <div class="col-12">&nbsp;</div>
            </div>
            <div class="row">
              <div
                class="col-12 text-center"
                v-if="$store.state.verification.email"
              >
                <!-- <k-button
                  v-if="$store.state.verification.email"
                  class="m-2"
                  :icon="'email'"
                  @click="mfaSend(1)"
                  >{{ maskingEmail($store.state.verification.email) }}</k-button
                > -->
                Verifying
                {{ maskingEmail($store.state.verification.email) }} email
                address...
              </div>
            </div>
          </k-form-element>
        </k-form>
        <vue-recaptcha
          hidden="true"
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="resetCaptcha"
          @error="errorMethod"
          size="invisible"
          :sitekey="$recaptchaSiteKey"
        >
        </vue-recaptcha>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      sendType: 0
    };
  },
  mounted() {
    if (
      !this.$store.state.verification ||
      !this.$store.state.verification.supportVarify
    ) {
      this.$router.push('/');
    }

    this.mfaSend(1);
  },
  methods: {
    onCaptchaVerified(token) {
      this.$store
        .dispatch('prepareOtp', {
          sendType: this.sendType,
          token: token
        })
        .then(() => {
          this.$router.replace('/verifyCode');
        })
        .catch((error) => console.log(error));
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
    errorMethod() {
      console.log('reCaptcha Error');
    },
    mfaSend(dataType) {
      this.sendType = dataType;
      if (!this.$refs.recaptcha.widgetId) this.resetCaptcha();
      this.$refs.recaptcha.execute();
    },
    maskingEmail(email) {
      if (email) {
        let account = email.split('@');
        let endStr = account[0].slice(-2);
        if (account[0].length > 4) {
          return (
            account[0].slice(0, 2) +
            endStr.padStart(account[0].length - 2, '*') +
            '@' +
            account[1]
          );
        } else {
          return endStr.padStart(account[0].length, '*') + '@' + account[1];
        }
      }
    }
  }
};
</script>
