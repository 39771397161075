<template>
  <div>
    <form-container class="payment-form-container" title="Order Confirmed">
      <template #header-icon>
        <icon-payment class="header-icon-payment" />
      </template>

      <template v-if="isSubscribed">
        <div class="subscribed-info">
          <div class="total-price-box">
            <icon-check-green class="icon-check-green" />
            <span class="total-text">Thank you for your order!</span>
          </div>
          <div class="additional-info">
            <div class="info">{{ selectedBundle.display }}</div>
            <div class="info">{{ count }}</div>
          </div>
          <div class="additional-info country-container">
            <div class="country info">
              <p>{{ selectedRegions.map((item) => item.text).join(', ') }}</p>
            </div>
          </div>
          <div class="additional-info tax-container">
            <div class="tax info">Tax:</div>
            <div class="tax info" v-if="!!tax">{{ tax.slice(1) }} USD</div>
          </div>
          <div class="additional-info">
            <div class="info">Total:</div>
            <div class="info total-price" v-if="!!totalPrice">
              {{ totalPrice.slice(1) }} USD
            </div>
          </div>
        </div>
      </template>
      <div class="footer">
        <p>
          Sold and fullfilled by
          <img
            style="margin-left: 10px"
            class="mb-4"
            width="135"
            src="@/assets/img/transparently-logo-black.svg"
            alt="logo"
          />
          - an authorized seller
        </p>
        <p>Privacy Policy | Terms of Sale</p>
      </div>
    </form-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormContainer from '@/components/tenantAdmin/formContainer.vue';

export default {
  components: { FormContainer },
  data() {
    return {
      count: 1
    };
  },
  computed: {
    ...mapGetters('fastspring', [
      'isSubscribed',
      'totalPrice',
      'selectedBundle',
      'selectedRegions',
      'extraSeatCount',
      'tax'
    ]),
    selectedBundleSeatCount() {
      return +((this.selectedBundle || {}).attributes || {}).seatCount;
    },
    extraSeat() {
      return (this.selectedBundle || {}).extraSeat;
    }
  },
  watch: {
    extraSeatCount: function (newVal) {
      // Update this.count whenever extraSeatCount changes
      this.count = this.selectedBundleSeatCount + newVal;
    }
  },
  mounted() {
    this.count = this.selectedBundleSeatCount + this.extraSeatCount;
  }
};
</script>

<style lang="scss" scoped>
.payment-form-container {
  flex: 1;
  max-width: 528px;

  ::v-deep .header-icon-payment {
    width: 21px;
    height: 16px;
    margin-bottom: 2px;
  }
}

.total-price {
  color: rgb(255, 255, 255) !important;
}
.footer p {
  margin: 0; /* Reset margin for both paragraphs */
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
  font-size: 12px;
}
.tax {
  margin-top: 20px;
  margin-bottom: 20px;
}
.no-form {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  z-index: 9999999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #13283d;

  &.hide {
    transition: all 500ms;
    opacity: 0;
    pointer-events: none;
  }
}

.icon-check-green {
  margin-bottom: 25px;
}
.subscribed-info {
  padding: 28px 20px;
}

.country {
  margin-top: 20px;
}

.country-container {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.total-price-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
  padding: 19px 10px 17px;
  justify-content: center;

  .total-text {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-right: 12px;
    justify-content: center;
  }

  .price {
    color: #34a54b;
    font-size: 18px;
    font-weight: 600;
  }
}

.additional-info {
  display: flex;
  justify-content: space-between;
}

.info {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
}

.update-subscription-btn {
  width: 100%;
  height: 42px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 34px;
}
</style>
