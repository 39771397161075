export const PANEL_BAR_KEYS = {
  SEARCH: 'search',
  WATCH_LISTS: 'watchLists',
  FILTERS: 'filters'
};

export const PANEL_BAR_ITEMS = [
  {
    title: '',
    expanded: true,
    content: PANEL_BAR_KEYS.SEARCH,
    id: PANEL_BAR_KEYS.SEARCH
  },
  {
    title: '',
    expanded: true,
    content: PANEL_BAR_KEYS.WATCH_LISTS,
    id: PANEL_BAR_KEYS.WATCH_LISTS
  },
  {
    title: '',
    expanded: true,
    content: PANEL_BAR_KEYS.FILTERS,
    id: PANEL_BAR_KEYS.FILTERS
  }
];

export const DEFAULT_OPEN_PANEL = PANEL_BAR_KEYS.FILTERS;
