import { checkIsAnnuallySubscription } from './subscription';
import { ANNUAL_SUBSCRIPTION_COUPON } from '../constants/fastspring';

export const SUBSCRIPTION_STATE = {
  ACTIVE: 'active',
  CANCELED: 'canceled'
};

export const getDropdownRegions = (state) => {
  return checkIsSubscribed(state)
    ? state.dropdownCostlyRegions
    : state.dropdownBundleRegions;
};

export const checkIsSubscribed = (state) => {
  return state.subscription && state.subscription.seatCount > 0;
};

export const pickRegionFields = (t) => ({
  id: t.pid,
  bigqueryRegionId:
    parseInt(t?.attributes?.bigqueryRegionId) ||
    parseInt(t?.attributes?.regionID),
  text: t.display || '',
  image: t.image,
  price: t.price,
  selected: t.selected || false,
  disabled: t.disabled || false
});

export const processBundle = (state, bundle) => {
  const { seatCount, regionCount } = bundle.attributes || {};

  bundle.seatCount = parseInt(seatCount);
  bundle.regionCount = parseInt(regionCount);
  bundle.extraSeat = (bundle.groups || []).find(
    (t) => t.display === 'ExtraSeat'
  )?.items?.[0];

  if (state.selectedBundle && state.selectedBundle.pid === bundle.pid) {
    const group = (bundle.groups || []).find((t) => t.display === 'Regions');
    const bundleRegionsGroup = (bundle.groups || []).find(
      (t) => t.display === 'BundleRegions'
    );

    if (group?.items) addRegions(state, group.items);
    if (bundleRegionsGroup?.items)
      addRegions(
        state,
        bundleRegionsGroup.items,
        'bundleRegions',
        'dropdownBundleRegions'
      );
  }
};

export function addRegions(
  state,
  items,
  regionsKey = 'costlyRegions',
  dropdownRegionsKey = 'dropdownCostlyRegions'
) {
  const regionCountriesMap = {};
  const otherCountries = [];

  items.forEach((product) => {
    const region = product?.attributes?.group;

    if (!region) {
      otherCountries.push(product);
      return;
    }

    if (regionCountriesMap[region]) {
      regionCountriesMap[region].push(product);
    } else {
      regionCountriesMap[region] = [product];
    }
  });

  state[regionsKey] = Object.entries(regionCountriesMap)
    .sort()
    .map(([key, value]) => ({
      region: key,
      countries: value.sort((a, b) => {
        if (a.display < b.display) {
          return -1;
        }

        if (a.display > b.display) {
          return 1;
        }

        return 0;
      })
    }));

  state[regionsKey].push({ region: 'Other', countries: otherCountries });

  state[dropdownRegionsKey] = state[regionsKey].map((product) => ({
    region: product.region,
    countries: product.countries.map(pickRegionFields)
  }));
}

export const removeUnselectedRegionIds = (value) => (region) => {
  region.countries.forEach((country) => {
    if (
      !value.find(
        ({ bigqueryRegionId }) => bigqueryRegionId === country.bigqueryRegionId
      )
    ) {
      country.selected = false;
    }
  });
};

class UserSubscriptionBuilder {
  getters;
  state;
  rootState;
  userSubscriptionDetail;

  constructor(rootState, state, getters, userSubscriptionDetail) {
    this.rootState = rootState;
    this.state = state;
    this.getters = getters;
    this.userSubscriptionDetail = userSubscriptionDetail;
  }

  getSelectedRegions() {
    return this.getters.selectedRegions.map((t) => t.id).filter((t) => t);
  }

  getSelectedRegionIds() {
    return this.getters.selectedRegions
      .map((t) => t.bigqueryRegionId)
      .filter((t) => t);
  }

  getSelectedSeatCount() {
    return this.state.selectedBundle
      ? Number(this.state.selectedBundle.attributes.seatCount) +
          this.state.extraSeatCount
      : 0;
  }

  getCurrentUserRegions() {
    const { regionProductIds } = this.state.subscription || {};

    if (!regionProductIds) {
      return [];
    }

    return JSON.parse(regionProductIds.length > 0 ? regionProductIds : '[]');
  }

  getSelectedRegionsProductIds() {
    const selectedRegions = this.getSelectedRegions();
    const currentUserRegions = this.getCurrentUserRegions();

    return selectedRegions && selectedRegions.length > 0
      ? selectedRegions
      : currentUserRegions;
  }

  getRemovedRegionProductIds() {
    const removedRegionProductIds = [];
    const currentUserRegions = this.getCurrentUserRegions();
    const selectedRegionsProductIds = this.getSelectedRegionsProductIds();

    for (const region of currentUserRegions) {
      if (!selectedRegionsProductIds.includes(region)) {
        removedRegionProductIds.push(region);
      }
    }

    return removedRegionProductIds;
  }

  getAddonProductIds() {
    const addonProductIds = [];
    const selectedRegionsProductIds = this.getSelectedRegionsProductIds();
    const currentUserRegions = this.getCurrentUserRegions();

    for (const region of selectedRegionsProductIds) {
      if (!currentUserRegions.includes(region)) {
        addonProductIds.push(region);
      }
    }

    return addonProductIds;
  }

  getRegionIds() {
    const { tenant } = this.state.subscription || {};
    const selectedRegionIds = this.getSelectedRegionIds();

    if (selectedRegionIds && selectedRegionIds.length > 0) {
      return selectedRegionIds;
    }

    return JSON.parse(
      tenant.region && tenant.region.length > 0 ? tenant.region : '[]'
    );
  }

  getRegionCount() {
    const { regionCount } = this.state.subscription || {};
    return this.getters.selectedRegions.length || regionCount;
  }

  build() {
    const isAnnuallySubscription = checkIsAnnuallySubscription(
      this.state.selectedBundle
    );
    const {
      nextChargeDate,
      nextChargeDateValue,
      nextChargeTotal,
      nextChargeTotalValue
    } = this.userSubscriptionDetail || {};
    const { tenantId } = this.rootState.principal;
    const { fastspringSubscriptionId, seatCount } =
      this.state.subscription || {};

    const requestData = {
      companyId: tenantId,
      fastspringSubscriptionId,
      extraSeatCount: this.state.extraSeatCount,
      regionProductIds: this.getSelectedRegionsProductIds(),
      regionIds: this.getRegionIds(),
      seatCount: this.getSelectedSeatCount() || seatCount,
      regionCount: this.getRegionCount(),
      nextChargeDate,
      nextChargeDateValue,
      nextChargeTotal,
      nextChargeTotalValue,
      removedRegionProductIds: this.getRemovedRegionProductIds(),
      addonProductIds: this.getAddonProductIds(),
      ...(isAnnuallySubscription
        ? { coupons: [ANNUAL_SUBSCRIPTION_COUPON] }
        : {})
    };

    return requestData;
  }
}

export const buildUserSubscriptionInformation = (
  { rootState, state, getters },
  userSubscriptionDetail
) => {
  if (state.selectedBundle) {
    state.selectedBundle.subscription.nextChargeTotal = state.totalPrice;
    state.selectedBundle.subscription.nextChargeTotalValue =
      state.totalPriceValue;
  }

  const userSubscriptionBuilder = new UserSubscriptionBuilder(
    rootState,
    state,
    getters,
    userSubscriptionDetail
  );

  return userSubscriptionBuilder.build();
};
