import axios from 'axios';

export default {
  methods: {
    async handleUpdateTenantManager(tenantId, userId, limit) {
      const response = await axios.post('tenant/update-tenant-manager', {
        tenantId,
        userId,
        limit
      });

      if (response.status === 200 && response.data) {
        return response.data.data;
      }
      return null;
    }
  }
};
