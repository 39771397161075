import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations('fastspring', [
      'SET_IS_FIRST_TENANT_ADMIN_LOGIN',
      'SET_TOTAL_PRICE',
      'CLEAR_SELECTED_REGIONS',
      'SET_EXTRA_SEAT_COUNT'
    ]),
    clearFastSpringUserInteraction() {
      this.SET_IS_FIRST_TENANT_ADMIN_LOGIN(true);
      this.SET_TOTAL_PRICE('');
      this.CLEAR_SELECTED_REGIONS();
      this.SET_EXTRA_SEAT_COUNT(0);
    }
  }
};
