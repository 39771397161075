import axios from 'axios';
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations('fastspring', ['SET_IS_LOADING']),
    async disableUsers(listIds, tenantId) {
      this.SET_IS_LOADING(true);

      await axios.post('/person/delete-multi', {
        listIds,
        tenantId
      });

      this.SET_IS_LOADING(false);
    }
  }
};
