import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  methods: {
    ...mapMutations('fastspring', [
      'INITIALIZE',
      'SET_IS_LOADING',
      'DATA_CHANGED',
      'INITIALIZE_SELECTED_BUNDLE',
      'INITIALIZE_SELECTED_COUNTRIES'
    ]),
    ...mapActions('fastspring', ['updateCart']),
    ...mapGetters('fastspring', ['selectedBundle', 'isSubscribed']),
    addSBL() {
      const scriptId = 'fsc-api';
      const existingScript = document.getElementById(scriptId);

      if (existingScript) {
        existingScript.remove();
      }
      const fastSpringStorefront = process.env.VUE_APP_FASTSPRINGSTOREFRONT;
      const script = document.createElement('script');

      window.fsDataCallBack = this.DATA_CHANGED;

      window.fsBeforeRequestCallback = () => {
        this.SET_IS_LOADING(true);
      };

      window.fsAfterRequestCallback = () => {
        this.SET_IS_LOADING(false);
      };

      script.type = 'text/javascript';
      script.id = scriptId;
      script.src =
        'https://sbl.onfastspring.com/sbl/0.9.2/fastspring-builder.min.js';
      script.dataset.storefront = fastSpringStorefront;
      script.setAttribute('data-data-callback', 'fsDataCallBack');
      script.setAttribute(
        'data-before-requests-callback',
        'fsBeforeRequestCallback'
      );
      script.setAttribute(
        'data-after-requests-callback',
        'fsAfterRequestCallback'
      );
      script.addEventListener('load', () => this.INITIALIZE(window.fastspring));

      document.head.appendChild(script);
    },

    fetchFastSpringSubscriptionDetail(finishedCallback) {
      window.fsAfterRequestCallback = () => {
        if (this.isSubscribed && !this.isInitialized) {
          this.isInitialized = true;

          this.INITIALIZE_SELECTED_BUNDLE();
          this.INITIALIZE_SELECTED_COUNTRIES();
          this.updateCart().then(() => {
            this.INITIALIZE_SELECTED_COUNTRIES();
            this.updateCart().then(() => {
              this.INITIALIZE_SELECTED_COUNTRIES();

              if (finishedCallback && typeof finishedCallback === 'function') {
                finishedCallback();
              }
            });
          });
        } else if (this.selectedBundle && !this.isInitialized) {
          this.isInitialized = true;

          this.INITIALIZE_SELECTED_BUNDLE();
          this.updateCart().then(() => {
            if (finishedCallback && typeof finishedCallback === 'function') {
              finishedCallback();
            }
          });
        } else {
          this.isInitialized = true;
          this.SET_IS_LOADING(false);
        }
      };
    }
  }
};
