export const EDITOR_TOOLS = [
  ['Bold', 'Italic', 'Underline', 'Strikethrough'],
  ['Subscript', 'Superscript'],
  'ForeColor',
  'BackColor',
  ['CleanFormatting'],
  ['AlignLeft', 'AlignCenter', 'AlignRight', 'AlignJustify'],
  ['Indent', 'Outdent'],
  ['OrderedList', 'UnorderedList'],
  'FontSize',
  'FontName',
  'FormatBlock',
  ['SelectAll'],
  ['Undo', 'Redo'],
  ['Link', 'Unlink', 'InsertImage', 'ViewHtml'],
  ['InsertTable', 'InsertFile'],
  ['Pdf', 'Print'],
  ['AddRowBefore', 'AddRowAfter', 'AddColumnBefore', 'AddColumnAfter'],
  ['DeleteRow', 'DeleteColumn', 'DeleteTable'],
  ['MergeCells', 'SplitCell']
];
