<template>
  <div>
    <div class="row header">
      <div class="col-4">
        <span>Terms & Conditions</span>
      </div>
      <div class="col-8 text-end">
        <k-button
          class="k-button-default"
          theme-color="secondary"
          fill-mode="solid"
          @click="showEditDialog"
        >
          Edit
        </k-button>
      </div>
    </div>

    <div class="container mt-8 left-indent tnc-body custom-scrollbar">
      <div class="row text-grey">
        <div class="col-2" v-if="entity.map.updated">
          Updated: {{ entity.map.updated }}
        </div>
      </div>
      <div class="row">
        <div class="col-6">Terms & Conditions</div>
      </div>
      <div class="row pt-8">
        <div class="col-12" v-html="entity.content"></div>
      </div>
    </div>

    <k-dialog
      class="dialog-md"
      v-if="editDialog == true"
      :title="' '"
      @close="closeEditDialog"
    >
      <div class="row mb-3">
        <div class="col-6"><b>Terms & Conditions</b></div>
        <div class="col-12">
          <k-editor
            :tools="tools"
            id="editor"
            :resizable-content="true"
            :default-content="entityEdit.content"
            style="height: 400px"
            rows="10"
            cols="30"
            :color="'white'"
            @focus="editorValidator"
            @select="editorValidator"
            @blur="editorValidator"
            @change="onChangeEditor"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button class="me-2" fill-mode="outline" @click="closeEditDialog"
            >Close</k-button
          >
          <k-button
            class="me-2 custom button"
            theme-color="primary"
            fill-mode="solid"
            @click="save"
            >Save</k-button
          >
        </div>
      </div>
    </k-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { EDITOR_TOOLS } from '@/shared/constants/editor';

export default {
  data() {
    return {
      entity: {
        map: {}
      },
      entityEdit: {
        map: {}
      },
      content: '<p>Lorem Ipsum</p>',
      editDialog: false,
      tools: EDITOR_TOOLS
    };
  },
  mounted() {
    this.getTermAndCondition();
  },
  methods: {
    getTermAndCondition() {
      axios
        .post('/tnc', {
          headers: {
            Authorization: `Bearer ${this.$store.state.principal.idToken}`
          }
        })
        .then((response) => {
          this.entity = response.data;
        });
    },
    save() {
      axios
        .post('/tnc/edit', this.entityEdit, {
          headers: {
            Authorization: `Bearer ${this.$store.state.principal.idToken}`
          }
        })
        .then((response) => {
          this.entity = response.data;
          this.closeEditDialog();
        });
    },
    showEditDialog() {
      this.entityEdit = Object.assign({}, this.entity);
      this.editDialog = true;
      this.$nextTick(() => {
        let iframe = document.querySelector('.k-content .k-iframe');
        iframe.addEventListener('load', (ev) => {
          const new_style_element = document.createElement('style');
          new_style_element.textContent = 'body { color: #8698ac; }';
          ev.target.contentDocument.head.appendChild(new_style_element);
        });
      });
    },
    closeEditDialog() {
      this.editDialog = false;
      this.entityEdit = {
        map: {}
      };
    },
    onChangeEditor(e) {
      this.entityEdit.content = e.html;
    }
  }
};
</script>
