<template>
  <k-dialog
    :title="' '"
    @close="closeDialog"
  >
    <div class="row mb-3">
      <div
        class="col-12 text-center"
        v-html="title"
      />
    </div>

    <form @submit.prevent="save">
      <div
        class="row"
        v-if="entity.uid"
      >
        <div class="col-12">
          User ID
          <k-inputs
            v-model="entity.uid"
            name="uid"
            :disabled="true"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          User Name
          <k-inputs
            v-model="entity.displayName"
            name="displayName"
            :required="true"
            placeholder="Eg. John Doe"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          Email
          <k-inputs
            v-model="entity.email"
            name="email"
            :required="true"
            placeholder="Eg. john.doe@mail.com"
            type="email"
            :disabled="true"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          Contact Phone
          <k-inputs
            v-model="entity.phoneNumber"
            name="phoneNumber"
            :required="true"
            placeholder="Eg. +6512345678"
            type="tel"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center">
          <k-button
            theme-color="secondary"
            fill-mode="outline"
            class="me-2"
            @click="closeDialog"
          >
            Cancel
          </k-button>

          <k-button
            type="submit"
            theme-color="primary"
          >
            Save
          </k-button>
        </div>
      </div>
    </form>
  </k-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ' '
    }
  },
  data() {
    return {
      entity: this.$root.clone(this.user)
    }
  },
  methods: {
    closeDialog() {
      this.entity = {};
      this.emitClose();
    },
    save() {
      this.$emit('click-save', this.entity);
      this.emitClose();
    },
    emitClose() {
      this.$emit('update:show', false);
    }
  }
}
</script>
