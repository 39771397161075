<template>
  <div>
    <div
      v-if="isReportFlag == false && detail.length > 1"
      class="container dashboard--chart-detail custom-scrollbar pe-3"
    >
      <div
        v-for="item in detail"
        :key="item.cluster_type"
        class="row dashboard__panel--card"
      >
        <div class="prevent-split">
          <div
            class="row border-card"
            :style="{ 'border-color': theColor.get(item.cluster_type) }"
          >
            <div class="row dashboard--large pe-0">
              <div class="col-8 report-card-header">
                <span :style="{ color: theColor.get(item.cluster_type) }">{{
                  item.cluster_name
                }}</span>
              </div>
              <div
                class="col-4 d-flex justify-content-end text-white report-card-header"
              >
                {{
                  item.cluster_quantile == 0
                    ? 'N/A'
                    : item.cluster_quantile + '/5'
                }}
              </div>
            </div>
            <div class="row dashboard--xsmall line-card pe-0">
              <div class="col-12 pe-0">
                {{ item.cluster_comment_text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isReportFlag == true">
      <div
        v-for="item in detail"
        :key="item.cluster_type"
        class="row dashboard__panel--card"
      >
        <span class="prevent-split">
          <div
            class="row border-card"
            :style="{ 'border-color': theColor.get(item.cluster_type) }"
          >
            <div class="row dashboard--large pt-2 pe-0">
              <div class="col-8 report-card-header">
                <span :style="{ color: theColor.get(item.cluster_type) }">{{
                  item.cluster_name
                }}</span>
              </div>
              <div
                class="col-4 d-flex justify-content-end text-dark report-card-header"
              >
                {{
                  item.cluster_quantile == 0
                    ? 'N/A'
                    : item.cluster_quantile + '/5'
                }}
              </div>
            </div>
            <div class="row dashboard--xsmall line-card pt-0 pb-2">
              <span>{{ item.cluster_comment_text }}</span>
              <div v-if="item.factor && item.factor.length > 0">
                <br />
                <ul class="ps-3 mb-1">
                  <div v-for="(fItem, indx) in item.factor" :key="indx">
                    <li>{{ fItem.text }}</li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'radialDetail',
    'isReportFlag',
    'tickerId',
    'year',
    'exchange_countryID'
  ],
  watch: {
    radialDetail: {
      handler: function (val) {
        this.detail = [];
        this.detail = [...val];
      },
      deep: true,
      loaded: false
    }
  },
  data() {
    return {
      detail: [],
      theColor: new Map([
        ['ac', '#4474A1'], //Accruals Management
        ['wo', '#BBB0AA'], //Working Capital Signals
        ['va', '#7A6E6D'], //Valuation Signals
        ['sm', '#FC9798'], //Smoothing Activity
        ['ma', '#F24A55'], //Margin Signals
        ['iv', '#74BDB6'], //Investing Activity
        ['ic', '#1C9898'], //Income Quality
        ['gr', '#F8CD4B'], //Growth Signals
        ['ge', '#BB9906'], //Gearing
        ['cr', '#68B063'], //Credit
        ['go', '#39A243'], //Corporate Governance
        ['ca', '#FDBB70'], //Cash Quality
        ['re', '#F88A08'], //Business Manipulation
        ['as', '#97CAE8'] //Asset Quality
      ])
    };
  },
  mounted() {
    if (this.isReportFlag == true) {
      this.detail = this.radialDetail;
      this.$nextTick(() => {
        this.$emit('rendered');
      });
    }
  }
};
</script>
