<template>
  <div class="bundles-page">
    <div class="header">
      <div class="container d-flex align-items-center">
        <icon-logo-header class="header__img" />
      </div>
    </div>

    <div class="body container">
      <div class="page-title">Choose your plan</div>

      <div class="bundle-list">
        <div
          v-if="!!trialSubscription && !principal.tenantId"
          :class="['bundle', trialSubscription.display]"
        >
          <SubscriptionCard :bundle="trialSubscription">
            <template v-slot:title>{{ trialSubscription.display }}</template>
            <template v-slot:price
              ><span :class="['interval', trialSubscription.display]">/mo</span>
              <span class="bundle__price__additional-text"
                >Before tax</span
              ></template
            >
            <template v-slot:region>
              <span>Anonymized Set</span>
            </template>
            <template v-slot:additional>
              <div
                class="bundle__additional-text"
                v-if="trialSubscription.subscription.instructions.length > 0"
              >
                Try a set of anonymized stocks for
                {{
                  trialSubscription.subscription.instructions[0]
                    .discountDurationLength
                }}
                days before you subscribe
              </div>
            </template>
            <template v-slot:footer>
              <k-button
                :class="['bundle__button', trialSubscription.display]"
                fill-mode="outline"
                @click="handleGetItNowClick(trialSubscription)"
              >
                Get it now
              </k-button>
            </template>
          </SubscriptionCard>
        </div>

        <template v-for="(bundles, index) in bundlesToDisplay">
          <dual-subscription-card
            :key="index"
            :bundles="bundles"
            @handleGetItNowClick="handleGetItNowClick"
          />
        </template>

        <div class="bundle">
          <SubscriptionCard>
            <template v-slot:title>Enterprise</template>
            <template v-slot:price>
              <p class="bundle__price Enterprise">
                Speak with us to obtain a customised quote based on
              </p>
            </template>

            <template v-slot:service>
              <div class="bundle__item-count">
                <icon-check-bold :class="['bundle__item-count__icon']" />
                <div class="bundle__item-count__text">Extended Data Access</div>
              </div>
              <div class="bundle__item-count">
                <icon-check-bold :class="['bundle__item-count__icon']" />
                <div class="bundle__item-count__text">
                  Minimum Seats Required
                </div>
              </div>
              <div class="bundle__item-count">
                <icon-check-bold :class="['bundle__item-count__icon']" />
                <div class="bundle__item-count__text">Billed Annually</div>
              </div>
            </template>

            <template v-slot:footer>
              <a
                href="https://www.transparently.ai/book-a-demo"
                target="_blank"
              >
                <k-button class="bundle__button" fill-mode="outline">
                  Contact sales
                </k-button>
              </a>
            </template>
          </SubscriptionCard>
        </div>
      </div>
    </div>

    <k-dialog
      v-if="notificationDialog.show"
      :title="' '"
      @close="notificationDialog.show = false"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p
            :style="{
              textAlign: 'center'
            }"
          >
            Do you wish to change your subscription plan to
            {{ selectedBundle.display }} Plan?
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="acceptToUpgradeSubscription"
          >
            Continue
          </k-button>
        </div>
      </div>
    </k-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import SubscriptionCard from './components/SubscriptionCard/SubscriptionCard.vue';
import DualSubscriptionCard from './components/SubscriptionCard/DualSubscriptionCard.vue';
import SLBMixin from '@/shared/mixins/slb';
import ClearFastSpringUserInteractionMixin from '@/shared/mixins/clearFastSpringUserInteraction';
import {
  TRIAL_BUNDLE_ID,
  TRIAL_BUNDLE
} from '@/components/tenantAdmin/constants/index';
import { checkIsAnnuallySubscription } from '@/shared/utils/subscription';

export default {
  mixins: [SLBMixin, ClearFastSpringUserInteractionMixin],
  components: {
    SubscriptionCard,
    DualSubscriptionCard
  },
  data: () => ({
    notificationDialog: {
      show: false
    }
  }),
  computed: {
    ...mapGetters(['principal']),
    ...mapGetters('fastspring', ['bundles', 'subscription', 'selectedBundle']),

    isUserSelectedTrialBundle() {
      return (
        this.subscription &&
        (this.subscription.productId === this.TRIAL_BUNDLE_ID ||
          !this.subscription.fastspringSubscriptionId)
      );
    },
    bundlesWithCost() {
      return this.bundles.filter(({ pid }) => pid !== this.TRIAL_BUNDLE_ID);
    },
    bundlesToDisplay() {
      const bundlesGroups = [];

      for (const bundle of this.bundlesWithCost) {
        if (!bundle.pid.includes('-annual')) {
          const bundles = [];

          // Find corresponding annual bundle of the montly bundle
          const annualBundle = this.bundlesWithCost.find(
            ({ pid }) => pid.includes(bundle.pid) && pid.includes('-annual')
          );

          if (annualBundle) {
            bundles.push(bundle);
            bundles.push(annualBundle);
          } else {
            bundles.push(bundle);
          }

          bundlesGroups.push(
            bundles.filter(
              ({ pid }) =>
                pid !== (this.subscription && this.subscription.productId)
            )
          );
        }
      }

      return bundlesGroups;
    },
    trialSubscription() {
      if (this.isUserSelectedTrialBundle) {
        return null;
      }

      const subscriptionBundleIndex = this.bundles.findIndex(
        ({ pid }) => pid === this.TRIAL_BUNDLE_ID
      );

      return subscriptionBundleIndex >= 0
        ? this.bundles[subscriptionBundleIndex]
        : null;
    }
  },
  created() {
    this.TRIAL_BUNDLE = TRIAL_BUNDLE;
    this.TRIAL_BUNDLE_ID = TRIAL_BUNDLE_ID;
    this.checkIsAnnuallySubscription = checkIsAnnuallySubscription;
  },
  async mounted() {
    //User logged in and don't have subscription => Logout so user can create new account
    if (this.principal && this.principal.idToken && !this.subscription) {
      this.clearFastSpringUserInteraction();
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/');
      });
    }
    this.addSBL();
    document.body.style.overflow = 'auto';
  },
  methods: {
    ...mapMutations('fastspring', ['SET_SELECTED_BUNDLE']),
    ...mapActions('fastspring', ['onUpgradeSubscriptionPlan']),

    async handleGetItNowClick(bundle) {
      this.SET_SELECTED_BUNDLE(bundle);

      // If user previously select trial bundle => Update user info
      if (this.principal.tenantId) {
        this.notificationDialog.show = true;
        return;
      }

      //Otherwise, create new user
      this.$router.push({
        path: '/addCompany',
        query: { bundle: bundle.product }
      });
    },
    async acceptToUpgradeSubscription() {
      await this.onUpgradeSubscriptionPlan();
      this.notificationDialog.show = false;
      this.clearFastSpringUserInteraction();

      this.$router.push({
        path: '/payment',
        query: { bundle: this.selectedBundle.product }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1550px;
}

.header {
  background-color: #13151a;
  padding: 20px 0;

  &__img {
    width: 204px;
    height: 72px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  color: #fff;
}

.page-title {
  font-size: 56px;
  font-weight: 600;
  text-align: center;
  margin: 116px 0;
}

.bundle-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bundle {
  border-radius: 20px;
  background: #181a1f;
  padding: 24px;
  min-height: 432px;
  width: 285px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &:not(:last-of-type) {
    margin-right: 24px;
  }

  &.Standard {
    background: #13283d;
  }

  &__price {
    &.Enterprise {
      font-size: 20px;
      line-height: 21px;
      font-weight: 500;
      margin-bottom: 0;
    }

    &__additional-text {
      font-size: 16px;
      line-height: 18px;
      display: block;
    }
  }

  &__description {
    color: #999;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 32px;
    margin-top: 16px;
  }

  &__additional-text {
    margin-bottom: 12px;
    color: rgba(255, 255, 255, 0.78);
  }

  &__button {
    border-radius: 8px;
    border: 1px solid #fff;
    color: #fff;
    background-color: transparent;
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 600;

    &.Standard {
      background: linear-gradient(90deg, #0c365f 0%, #24496f 100%);
      border: 1px solid #13283d;
    }

    &--contact {
      text-align: center;
      text-decoration: none;
    }
  }

  &__billed-annually {
    color: #528ac5;
    font-size: 32px;
    font-weight: 700;
    text-decoration-line: underline;
  }
}
</style>
