export const DISCOUNT_PERCENTS = {
  1: { value: 25 },
  2: { value: 34 },
  3: { value: 38 },
  4: { value: 40 },
  5: { value: 42 },
  6: { value: 43 },
  7: { value: 44 },
  8: { value: 45 },
  9: { value: 46, range: true },
  10: { value: 46, hide: true },
  11: { value: 46, hide: true },
  12: { value: 47, range: true },
  13: { value: 47, hide: true },
  14: { value: 47, hide: true },
  15: { value: 47, hide: true },
  16: { value: 47, hide: true },
  17: { value: 47, hide: true },
  18: { value: 47, hide: true },
  19: { value: 48, range: true }
};

export const ANNUAL_SUBSCRIPTION_DISCOUNT_PERCENTS = 20;
export const ANNUAL_SUBSCRIPTION_COUPON = 'ANNUAL-SUBSCRIPTION-DISCOUNT';
