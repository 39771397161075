import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters(['principal']),
    ...mapGetters('fastspring', ['subscription'])
  },
  methods: {
    ...mapMutations('fastspring', [
      'SET_SUBSCRIPTION',
      'SET_FASTSPRING_SUBSCRIPTION'
    ]),

    async getSubscriptionInfo() {
      if (this.principal.tenantId) {
        const response = await axios.get(
          `/payment/subscription/${this.principal.tenantId}`
        );

        if (response.status === 200 && response.data) {
          this.SET_SUBSCRIPTION(response.data);
        }

        const { fastspringSubscriptionId } = this.subscription || {};

        if (fastspringSubscriptionId) {
          const response = await axios.get(
            `/payment/subscriptions/${fastspringSubscriptionId}`
          );

          if (response.status === 200 && response.data) {
            this.SET_FASTSPRING_SUBSCRIPTION(response.data.data);
          }
        }
      }
    }
  }
};
