<template>
  <div class="bundles-page">
    <div v-if="showCheckout" class="body container">
      <order-form class="form-container" :showEditButton="false" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import OrderForm from '@/components/tenantAdmin/orderForm.vue';
import getUserSubscriptionMixin from './mixins/getUserSubscription';
import SLBMixin from '@/shared/mixins/slb';

export default {
  mixins: [getUserSubscriptionMixin, SLBMixin],
  components: {
    OrderForm
  },
  data() {
    return {
      showCheckout: false,
      isInitialized: false
    };
  },
  computed: {
    ...mapGetters('fastspring', [
      'selectedBundle',
      'subscription',
      'isSubscribed'
    ]),
    isAgreedTerms() {
      return this.subscription && this.subscription.agreedTerms;
    }
  },
  watch: {
    showCheckout() {
      if (this.showCheckout) this.$emit('onLoadDataCompleted');
    }
  },
  async mounted() {
    await this.getSubscriptionInfo();

    if (!this.selectedBundle) {
      const bundle = { pid: this.subscription.productId };
      this.SET_SELECTED_BUNDLE(bundle);
    }

    if (this.isAgreedTerms) {
      this.initFastSpring();
      document.body.style.overflow = 'auto';
    }
  },
  methods: {
    ...mapMutations('fastspring', [
      'INITIALIZE',
      'DATA_CHANGED',
      'SET_SELECTED_BUNDLE',
      'SET_IS_LOADING',
      'INITIALIZE_SELECTED_COUNTRIES',
      'INITIALIZE_SELECTED_BUNDLE'
    ]),
    ...mapActions('fastspring', ['updateCart', 'onPaymentSuccess']),
    initFastSpring() {
      const scriptId = 'fsc-api';
      const existingScript = document.getElementById(scriptId);

      if (existingScript) {
        existingScript.remove();
      }

      const fastSpringStorefront = process.env.VUE_APP_FASTSPRINGSTOREFRONT;
      const script = document.createElement('script');

      window.fsDataCallBack = this.DATA_CHANGED;

      window.fsBeforeRequestCallback = () => {
        this.SET_IS_LOADING(true);
      };

      window.fsAfterRequestCallback = () => {
        if (this.isSubscribed && !this.isInitialized) {
          this.isInitialized = true;

          this.INITIALIZE_SELECTED_BUNDLE();
          this.INITIALIZE_SELECTED_COUNTRIES();
          this.updateCart().then(() => {
            this.INITIALIZE_SELECTED_COUNTRIES();
            this.updateCart().then(() => {
              this.INITIALIZE_SELECTED_COUNTRIES();
              this.showCheckout = true;
              this.SET_IS_LOADING(false);
            });
          });
        } else if (this.selectedBundle && !this.isInitialized) {
          this.isInitialized = true;

          this.INITIALIZE_SELECTED_BUNDLE();
          this.updateCart().then(() => {
            this.isInitialized = true;
            this.showCheckout = true;
            this.SET_IS_LOADING(false);
          });
        } else {
          this.isInitialized = true;
          this.SET_IS_LOADING(false);
        }
      };

      window.fsPopupWebhookReceived = this.onPaymentSuccess;

      script.type = 'text/javascript';
      script.id = scriptId;
      script.src =
        'https://sbl.onfastspring.com/sbl/0.9.2/fastspring-builder.min.js';
      script.dataset.storefront = fastSpringStorefront;
      script.setAttribute('data-data-callback', 'fsDataCallBack');
      script.setAttribute(
        'data-before-requests-callback',
        'fsBeforeRequestCallback'
      );
      script.setAttribute(
        'data-after-requests-callback',
        'fsAfterRequestCallback'
      );
      script.setAttribute(
        'data-popup-webhook-received',
        'fsPopupWebhookReceived'
      );
      script.addEventListener('load', () => this.INITIALIZE(window.fastspring));

      document.head.appendChild(script);
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  justify-content: center;
  padding: 0 10px 24px;
  color: #fff;
}

.form-container {
  margin-right: 0;
}
</style>
