<template>
  <div class="bundles-page">
    <div class="header">
      <div class="container d-flex justify-content-between align-items-center">
        <icon-logo-header class="header__img" />

        <div>
          <k-button
            v-if="isSubscribed"
            class="header__button me-3"
            @click="goTo('/dashboard')"
          >
            Dashboard
          </k-button>
          <k-button class="header__button me-3" @click="goTo('/manageUsers')">
            Manage Users
          </k-button>
        </div>
      </div>
    </div>

    <div v-if="showCheckout" class="body container">
      <cancel-subscription-form class="form-container" />
      <payment-form
        class="form-container"
        :isShowingUpdateSubscriptionBtn="false"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CancelSubscriptionForm from '@/components/tenantAdmin/components/CancelSubscription/cancel-subscription-form.vue';
import PaymentForm from '@/components/tenantAdmin/paymentForm.vue';
import getUserSubscriptionMixin from './mixins/getUserSubscription';
import SLBMixin from '@/shared/mixins/slb';

export default {
  mixins: [getUserSubscriptionMixin, SLBMixin],
  components: {
    PaymentForm,
    CancelSubscriptionForm
  },
  data() {
    return {
      showCheckout: false,
      isInitialized: false
    };
  },
  computed: {
    ...mapGetters(['principal']),
    ...mapGetters('fastspring', [
      'selectedBundle',
      'subscription',
      'isSubscribed'
    ]),
    isAgreedTerms() {
      return this.subscription && this.subscription.agreedTerms;
    }
  },
  async mounted() {
    await this.getSubscriptionInfo();

    if (!this.selectedBundle) {
      const bundle = { pid: this.subscription.productId };
      this.SET_SELECTED_BUNDLE(bundle);
    }

    if (this.isAgreedTerms) {
      this.initFastSpring();
      document.body.style.overflow = 'auto';
    }
  },
  methods: {
    ...mapMutations('fastspring', ['SET_SELECTED_BUNDLE']),
    ...mapActions('fastspring', ['onPaymentSuccess']),
    goTo(url) {
      this.$router.push(url);
    },
    initFastSpring() {
      this.addSBL();
      this.fetchFastSpringSubscriptionDetail(() => {
        this.showCheckout = true;
        this.SET_IS_LOADING(false);
      });
      window.fsPopupWebhookReceived = this.onPaymentSuccess;
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #13151a;
  padding: 20px 0;

  &__img {
    width: 204px;
    height: 72px;
  }

  &__button {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 14px 22px 14px 24px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    height: 48px;
  }
}

.payment {
  width: 500px;
}
.body {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  margin-top: 72px;
  color: #fff;
}
</style>
