import {
  TRIAL_BUNDLE_ID,
  TRIAL_DAYS,
  DAYS_BEFORE_TRIAL_END_TO_SHOW_NOTICE
} from '@/components/tenantAdmin/constants';
import { SUBSCRIPTION_STATE } from '@/shared/utils/store-fastspring';
import { addDays, differenceInDays, parse } from 'date-fns';

export const checkIsUserUsingTrialSubscription = (subscription) => {
  const { productId } = subscription || {};

  return productId === TRIAL_BUNDLE_ID;
};

export const checkIsTrialSubscriptionAboutToEnd = (subscription) => {
  const startDate = parse(
    subscription.tenant.startDate,
    'yyyy-MM-dd',
    new Date()
  );
  const trialEndDate = addDays(startDate, TRIAL_DAYS);
  const daysToEndDate = differenceInDays(trialEndDate, new Date());

  return daysToEndDate <= DAYS_BEFORE_TRIAL_END_TO_SHOW_NOTICE;
};

export const checkIsUserSubscriptionExpired = (subscription) => {
  const { expiredTime } = subscription || {};

  if (checkIsUserUsingTrialSubscription(subscription)) {
    if (expiredTime && new Date().getTime() > new Date(expiredTime).getTime()) {
      return true;
    }
  }

  return false;
};

export const checkIsUserHaveNoSeat = (subscription) => {
  const { seatCount } = subscription || {};

  if (!seatCount || Number(seatCount) === 0) {
    return true;
  }

  return false;
};

export const checkIsUserPaidForSubscription = (subscription) => {
  const { nextChargeDateValue } = subscription || {};

  if (nextChargeDateValue) {
    //If current time is before next charge date => User haven't paid yet
    if (new Date(nextChargeDateValue).getTime() < new Date().getTime()) {
      return false;
    }
  }

  return true;
};

export const checkIsSubscriptionCanceled = (
  subscription,
  fastSpringSubscription
) => {
  const { state } = fastSpringSubscription || {};
  const { fastspringSubscriptionId } = subscription || {};

  if (
    !fastspringSubscriptionId ||
    checkIsUserUsingTrialSubscription(subscription)
  )
    return false;

  if (
    state === SUBSCRIPTION_STATE.ACTIVE ||
    (state !== SUBSCRIPTION_STATE.ACTIVE &&
      checkIsUserPaidForSubscription(subscription))
  ) {
    return false;
  }

  return true;
};

export const checkIsAnnuallySubscription = (subscription) => {
  if (!subscription) return false;

  return subscription.future.intervalUnit === 'year';
};
