<template>
  <form-container class="order-form-container" :title="formTitle">
    <template #header-icon>
      <icon-order-details class="header-icon-order" />
    </template>

    <seats-form class="mb-4" :isModifying="isModifying" />

    <div
      v-if="
        isSubscribed && !isModifying && !isPaymentCompleted && showEditButton
      "
      class="modify-btn-container"
    >
      <k-button
        class="modify-btn"
        theme-color="primary"
        @click="handleModifySubscriptionClick"
      >
        Modify Subscription
      </k-button>
    </div>

    <regions-form :isModifying="isModifying" />
  </form-container>
</template>

<script>
import SeatsForm from '@/components/tenantAdmin/seatsForm.vue';
import RegionsForm from '@/components/tenantAdmin/regionsForm.vue';
import { mapGetters } from 'vuex';
import FormContainer from '@/components/tenantAdmin/formContainer.vue';

export default {
  props: {
    showEditButton: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    isModifying: {
      type: Boolean,
      default: function () {
        return false;
      }
    }
  },
  components: {
    FormContainer,
    RegionsForm,
    SeatsForm
  },
  computed: {
    ...mapGetters('fastspring', [
      'selectedBundle',
      'isSubscribed',
      'isPaymentCompleted'
    ]),
    formTitle() {
      return `Your Order (${(this.selectedBundle || {}).display} Plan)`;
    }
  },
  methods: {
    handleModifySubscriptionClick() {
      this.$emit('onSetIsModifying', true);
    }
  }
};
</script>

<style lang="scss" scoped>
.order-form-container {
  flex: 2;
  margin-right: 24px;
  display: flex;
  flex-direction: column;

  ::v-deep {
    .header-icon-order {
      width: 24px;
      height: 24px;
    }

    .form-container-body {
      padding: 24px;
      display: flex;
      flex-direction: column;
    }
  }
}

.modify-btn-container {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 6px;
}

.modify-btn {
  width: 292px;
  height: 42px;
  max-width: 100%;
  font-size: 18px;
  font-weight: 600;
}
</style>
