<template>
  <div>
    <div class="row header align-items-center">
      <div class="col-4">
        <span>Announcements</span>
      </div>
      <div class="col-8 d-flex justify-content-end">
        <k-button
          @click="showAddDialog"
          theme-color="primary"
          fill-mode="solid"
        >
          New Announcement
        </k-button>
      </div>
    </div>
    <k-grid
      class="d-flex justify-content-center"
      :data-items="dataResult"
      :pageable="pageable"
      :sortable="sortable"
      :sort="sort"
      :skip="skip"
      :take="take"
      :columns="columns"
      @datastatechange="dataStateChange"
      @rowclick="rowClick"
      scrollable="none"
    >
      <template v-slot:contentFormat="{ props }">
        <td>{{ removeFormat(props.dataItem[props.field]) }}</td>
      </template>
      <template v-slot:actionTemplate="{ props }">
        <td>
          <div
            class="d-flex flex-row justify-content-between align-items-center"
          >
            <k-button
              class="custom-button"
              theme-color="secondary"
              fill-mode="solid"
              rounded="full"
              @click="showEditDialog(props.dataItem)"
              ><icon-edit
            /></k-button>
            <k-button
              class="custom-button"
              theme-color="secondary"
              fill-mode="solid"
              rounded="full"
              @click="showDeleteConfirmationDialog(props.dataItem)"
              ><icon-trash
            /></k-button>
          </div>
        </td>
      </template>
    </k-grid>

    <k-dialog
      class="dialog-md"
      v-if="dialog == true"
      :title="' '"
      @close="dialog = false"
    >
      <div class="row mb-3">
        <div
          v-if="entity.id == null"
          class="col-12 font-weight-bold fw-bold"
          v-text="'New Announcement'"
        ></div>
      </div>
      <div class="row">
        <div class="col-12">
          <k-inputs
            v-model="entity.title"
            :placeholder="'Announcement Title'"
            :max-length="255"
            @focus="titleValid = true"
            :validity-styles="titleValid"
            :required="true"
          ></k-inputs>
        </div>
        <div class="row">
          <div class="col-4">
            <span>Start</span>
            <k-date-picker
              v-model="entity.startDate"
              :min="new Date()"
            ></k-date-picker>
          </div>
          <div class="col-4">
            <span>End</span>
            <k-date-picker
              v-model="entity.endDate"
              :min="entity.startDate"
            ></k-date-picker>
          </div>
        </div>
        <div class="row"><span>Content</span></div>
        <div id="colEditor" class="col-12">
          <k-editor
            :tools="tools"
            class="announcement-editor"
            :resizable-content="true"
            :default-content="entity.content"
            :placeholder="'Content'"
            style="height: 400px"
            rows="10"
            cols="30"
            @focus="editorValidator"
            @select="editorValidator"
            @blur="editorValidator"
            @change="onChangeEditor"
          />
        </div>
        <div class="d-flex col-12 justify-content-end">
          <span>Updated: {{ entity.updated }}</span>
        </div>
        <div class="row">
          <div class="col-12">&nbsp;</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <k-button
            theme-color="secondary"
            fill-mode="outline"
            class="me-2"
            @click="closeDialog"
          >
            Cancel
          </k-button>
          <k-button theme-color="primary" @click="handleSubmit">
            Save
          </k-button>
        </div>
      </div>
    </k-dialog>
    <k-dialog
      v-if="deleteConfirmationDialog.show"
      :title="' '"
      @close="closeDeleteConfirmationDialog"
    >
      <div class="row mb-3">
        <div class="col-12">
          <p class="fw-bold">
            Are you sure you want to delete
            {{ deleteConfirmationDialog.dataItem.title }}?
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-end">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="closeDeleteConfirmationDialog"
            >Close</k-button
          >
          <k-button
            theme-color="success"
            @click="deleteItem(deleteConfirmationDialog.dataItem.id)"
            >Confirm</k-button
          >
        </div>
      </div>
    </k-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import { EDITOR_TOOLS } from '@/shared/constants/editor';

export default {
  data() {
    return {
      announcements: [
        {
          id: null,
          title: null,
          content: null,
          startDate: null,
          endDate: null
        }
      ],
      titleValid: false,
      entity: {
        id: null,
        title: null,
        content: null,
        startDate: null,
        endDate: null
      },
      pageable: true,
      sortable: true,
      skip: 0,
      take: 10,
      sort: [{ field: 'startDate', dir: 'desc' }],
      filter: null,
      columns: [
        { field: 'title', title: 'Title', width: '100px' },
        {
          field: 'content',
          title: 'Content',
          cell: 'contentFormat',
          width: '300px'
        },
        { field: 'startDate', title: 'Start', width: '80px' },
        { field: 'endDate', title: 'End', width: '80px' },
        { field: 'version', title: 'Version', width: '80px' },
        { field: '', title: '', cell: 'actionTemplate', width: '26px' }
      ],
      dataResult: [],
      gridClickedRow: {},
      dialog: false,
      deleteConfirmationDialog: {
        show: false,
        dataItem: {}
      },
      tools: EDITOR_TOOLS
    };
  },
  mounted() {
    this.dataList();
  },
  methods: {
    dataList() {
      axios.get('/announcement/list').then((response) => {
        this.announcements = response.data;
        const dataState = {
          skip: this.skip,
          take: this.take,
          sort: this.sort
        };
        this.dataResult = process(this.announcements, dataState);
      });
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);
      this.dataResult = process(this.announcements, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter
      });
    },
    rowClick(event) {
      this.windowVisible = true;
      this.gridClickedRow = event.dataItem;
    },
    showAddDialog() {
      this.entity = {
        startDate: new Date(),
        endDate: new Date()
      };
      this.dialogPop();
    },
    showEditDialog(item) {
      this.entity = Object.assign({}, item);
      this.entity.startDate = new Date(item.startDate);
      this.entity.endDate = new Date(item.endDate);
      this.dialogPop();
    },
    dialogPop() {
      this.dialog = true;
      this.$nextTick(() => {
        let iframe = document.querySelector('.k-content .k-iframe');
        iframe.addEventListener('load', (ev) => {
          const new_style_element = document.createElement('style');
          new_style_element.textContent = 'body { color: white; }';
          ev.target.contentDocument.head.appendChild(new_style_element);
        });
        this.$forceUpdate();
      });
    },
    closeDialog() {
      this.titleValid = false;
      this.dialog = false;
      this.entity = {
        map: {}
      };
    },
    save() {
      let url = '/announcement/add';
      if (this.entity.id) {
        url = '/announcement/edit';
      }
      axios.post(url, this.entity).then((response) => {
        if (response.status == 200) {
          this.closeDialog();
          this.dataList();
        }
      });
    },
    showDeleteConfirmationDialog(dataItem) {
      this.deleteConfirmationDialog.dataItem = dataItem;
      this.deleteConfirmationDialog.show = true;
    },
    closeDeleteConfirmationDialog() {
      this.deleteConfirmationDialog = {
        show: false,
        dataItem: {}
      };
    },
    deleteItem(id) {
      axios.post('/announcement/delete', { id: id }).then((response) => {
        if (response.status == 200) {
          this.dataList();
          this.closeDeleteConfirmationDialog();
        }
      });
    },
    handleSubmit() {
      this.titleValid = true;
      this.editorValidator();
      if (
        !this.entity.title ||
        !this.entity.content ||
        this.entity.startDate > this.entity.endDate
      ) {
        return;
      }
      this.save();
    },
    editorValidator() {
      this.$nextTick(() => {
        if (!this.entity.content) {
          document.getElementById('colEditor').classList.add('custom-invalid');
        } else {
          document
            .getElementById('colEditor')
            .classList.remove('custom-invalid');
        }
      });
    },
    onChangeEditor(e) {
      this.entity.content = e.html;
    },
    removeFormat(value) {
      return (
        value
          .replace(/(<([^>]+)>)/gi, '')
          .replace(/(\r\n|\n|\r)/gm, '')
          .substring(0, 60) + (value.length > 60 ? '...' : '')
      );
    }
  }
};
</script>
