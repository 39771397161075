var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-chart',{style:({ height: _vm.size }),attrs:{"renderAs":'canvas',"transitions":false}},[_c('k-chart-area',{attrs:{"background":'transparent'}}),_c('k-chart-tooltip',{attrs:{"visible":true}}),_c('k-chart-legend',{attrs:{"visible":true}}),_c('k-chart-series',[_c('k-chart-series-item',{attrs:{"type":'area',"data-items":_vm.data,"line":{ style: _vm.smoothLine },"markers":{ visible: false },"color":_vm.color,"stack":false}})],1),_c('k-chart-valueaxis',[_c('k-chart-valueaxis-item',{attrs:{"majorGridLines":{ visible: false },"visible":false}})],1),_c('k-chart-categoryaxis',[_c('k-chart-categoryaxis-item',{attrs:{"color":'#909090',"majorGridLines":{ visible: false },"majorTicks":{ visible: false },"minorTicks":{ visible: false },"categories":_vm.category,"visible":true,"labels":{
        visible: true,
        font: this.fontSize + ' lato',
        step: Math.round(_vm.category.length / 5) - 1,
        template: _vm.template
      },"notes":{
        line: {
          length: _vm.pointerLine,
          width: 2,
          color: '#F24A55'
        },
        icon: {
          background: '#F24A55',
          size: 10
        },
        data: [
          {
            value: (_vm.point < 1 ? 1 : _vm.point) - 1
          }
        ]
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }