export const REASONS = {
  EXPENSIVE: 'expensive',
  LOW_FREQUENCY: 'lowfrequency',
  OBSOLETE: 'obsolete',
  COMPETITION: 'competition',
  ISSUES: 'issues',
  EXPECTATION: 'expectation',
  OTHER: 'other'
};

export const CANCELLATION_REASONS = [
  {
    value: REASONS.EXPENSIVE,
    label: 'It is too expensive'
  },
  {
    value: REASONS.LOW_FREQUENCY,
    label: "I don't use it very often"
  },
  {
    value: REASONS.OBSOLETE,
    label: "I don't need it any more"
  },
  {
    value: REASONS.COMPETITION,
    label: 'I found a better alternative'
  },
  {
    value: REASONS.ISSUES,
    label: 'I experienced issues using it'
  },
  {
    value: REASONS.EXPECTATION,
    label: "It didn't meet my expectations"
  },
  {
    value: REASONS.OTHER,
    label: 'Other'
  }
];
