import axios from 'axios';

export default {
  data() {
    return {
      watchlistNotMatch: [],
      watchlistMessage: '',
      watchlistFooterMessage: '',
      watchlistNotMatchDialog: false,
      restrictAccessWatchlist: false,
      replacedExchangeWatchlist: new Set(),
      blockedExchangeWatchlist: new Set(),
      currentWatchlistName: '',
      validWatchlistName: true,
      selectedItemWatchList: { name: '' },
      columnWatchlists: [
        { field: 'isin', title: 'ISIN', width: 100 },
        { field: 'name', title: 'Name' },
        { field: 'domicile_country', title: 'Domicile', width: 100 },
        { field: 'exchange_country', title: 'Exchange country', width: 100 },
        {
          field: 'final_score',
          title: 'Risk',
          cell: 'scoreFormat',
          width: 80
        },
        {
          field: 'rating',
          title: 'Rating',
          width: 100
        },
        { cell: 'actionTemplate', width: 30 }
      ]
    };
  },
  mounted() {
    this.popupNotificationWidget = this.$refs.popupNotification.kendoWidget();
  },
  methods: {
    csvToArray(str, delimiter = ',') {
      // handling newline issue
      str = str.replace(/(\r\n|\n|\r)/gm, '\n');

      // slice from start of text to the first \n index
      // use split to create an array from string by delimiter
      const headers = str.slice(0, str.indexOf('\n')).split(delimiter);

      let idx = str.indexOf('\n') + 1;
      if (idx == 0) {
        return [];
      }

      // slice from \n index + 1 to the end of the text
      // use split to create an array of each csv value row
      const tmpRows = str.slice(idx).split('\n');
      //removing unformatted or empty line
      const rows = tmpRows.filter((row) => row);
      // Map the rows
      // split values from each row into an array
      // use headers.reduce to create an object
      // object properties derived from headers:values
      // the object passed as an element of the array
      const arr = rows.map(function (row) {
        const values = row.split(delimiter);
        const el = headers.reduce(function (object, header, index) {
          object[header] = values[index];
          return object;
        }, {});
        return el;
      });

      // return the array
      return arr;
    },

    async checkForISIN(item, fileName, data) {
      const { isin } = item || {};

      if (!isin) {
        this.watchlistNotMatch.push('Error empty stock data uploaded');
        return {
          status: false,
          message: 'Upload failed. Make sure your data is correct.'
        };
      }

      const response = await axios.post('/dashboard/isin', {
        isin
      });

      let status, isDefault, message;

      if (
        response.status != 200 ||
        !response.data ||
        response.data.isin != isin ||
        !response.data.tickerID
      ) {
        if (response.data.isRestrictAccess) {
          this.watchlistNotMatch.push(
            `${isin}, due to either restricted access or permission being needed for that Exchange country.`
          );
          status = false;
          isDefault = false;
          message =
            "You don't currently have a permission to upload the data with this Exchange country. The data will not be uploaded.";
        } else {
          status = false;
          isDefault = false;
          message =
            'Data match warning - Some data did not match with database.';
        }
      } else {
        const {
          tickerID,
          name: company_name,
          domicile_country,
          exchange_country,
          exchange_countryID,
          final_score,
          rating
        } = response.data || {};

        const newItem = {
          ...item,
          tickerID,
          company_name,
          domicile_country,
          exchange_country,
          exchange_countryID,
          final_score:
            item.risk_score && !isNaN(item.risk_score)
              ? item.risk_score
              : final_score,
          watchlist_name: item.watchlist_name ? item.watchlist_name : fileName,
          status: true,
          rating
        };

        data.splice(
          data.findIndex(
            (obj) =>
              item.isin == obj.isin &&
              item.exchange_countryID == obj.exchange_countryID &&
              item.watchlist_name == obj.watchlist_name
          ),
          1,
          newItem
        );

        status = true;
        isDefault = false;
        message = 'Upload complete.';
      }

      return {
        status,
        isDefault,
        isin,
        message
      };
    },

    addCompaniesToWatchlist(data, response) {
      const watchlistTemp = [];

      for (let item of data) {
        const {
          isin,
          company_name,
          domicile_country,
          exchange_countryID,
          exchange_country,
          watchlist_name,
          status,
          tickerID,
          final_score,
          rating
        } = item || {};
        // Handling empty row
        let responseTmp = response.find((x) => x.isin == isin);

        if (
          !responseTmp.status ||
          !isin ||
          !company_name ||
          !domicile_country ||
          !exchange_countryID ||
          !exchange_country ||
          !watchlist_name
        ) {
          continue;
        }

        let index = watchlistTemp.findIndex((x) => x.name == watchlist_name);
        let company = null;

        if (status) {
          company = {
            tickerID: parseInt(tickerID),
            isin,
            name: company_name,
            domicile_country,
            exchange_countryID: parseInt(exchange_countryID),
            exchange_country,
            rating,
            final_score
          };
        }

        if (index >= 0) watchlistTemp[index].companies.push(company);
        else {
          watchlistTemp.push({
            name: watchlist_name,
            companies: company ? [company] : []
          });
        }
      }

      return watchlistTemp;
    },

    updateWatchlistName(watchlistTemp) {
      for (let watchlist of watchlistTemp) {
        let number = 0;
        let result = this.$store.state.watchlists.filter(
          ({ name }) =>
            name == watchlist.name ||
            name.substr(0, name.lastIndexOf('(')) == watchlist.name
        );
        if (result.length > 0) {
          number = Math.max(
            ...result.map((x) => {
              if (x.name == watchlist.name) {
                return 0;
              }
              let index = x.name.lastIndexOf('(');
              let n = x.name.substr(index).replace('(', '').replace(')', '');
              return parseInt(isNaN(n) || n == x.name ? 0 : n);
            })
          );
          watchlist.name = `${watchlist.name}(${parseInt(number + 1)})`;
        }

        this.$store.commit('saveWatchlist', watchlist);
      }
    },

    showUploadWatchlistMessage(response, data) {
      let trueResponse = response.find((x) => !!x.status);
      let falseResponse = response.find((x) => !x.status);
      let defaultResponse = response.find((x) => !!x.isDefault);

      if (
        this.watchlistNotMatch.length > 0 &&
        ((trueResponse && falseResponse) || defaultResponse)
      ) {
        // Updated based on Peter's suggestion and approved by the client
        this.watchlistMessage =
          'Some stocks did not upload, as the request included stocks which are either not compatible with the latest dataset ' +
          "or those stocks require permission to upload data from that Exchange country (you don't currently have access to that Exchange country).";
        this.watchlistFooterMessage =
          'Please see your list of Saved Watchlist for more details about the successfully uploaded data';
        this.watchlistNotMatchDialog = true;
      } else if (
        (this.watchlistNotMatch.length > 0 && falseResponse) ||
        falseResponse
      ) {
        this.popupNotificationWidget.show(falseResponse.message, 'error');
      } else if (data.length == 0) {
        this.popupNotificationWidget.show('Empty Data', 'error');
      } else {
        this.popupNotificationWidget.show(response[0].message, 'success');
      }
    },

    async uploadWatchlist(e) {
      const file = e.target.files[0];
      const reader = new FileReader();

      if (!(file.name && file.name.split('.').pop() == 'csv')) {
        this.popupNotificationWidget.show('Unsupported file type', 'warning');
        this.$refs.watchlistFile.value = null;
        return;
      }

      let vm = this;

      let fileName = file.name.split('.')[0];

      this.watchlistNotMatch = [];

      reader.onload = async (e) => {
        const promises = [];
        const data = vm.csvToArray(e.target.result);

        for (let item of data) {
          promises.push(this.checkForISIN(item, fileName, data));
        }

        const response = await Promise.all(promises);
        const watchlistTemp = this.addCompaniesToWatchlist(data, response);

        this.updateWatchlistName(watchlistTemp);
        this.showUploadWatchlistMessage(response, data);
      };
      reader.readAsText(file);
      this.$refs.watchlistFile.value = null;
    },

    setWatchlistCompanies(defaultExchanges, validExchanges, prewatchlist) {
      for (const defaultExchange of defaultExchanges) {
        for (const validExchange of validExchanges) {
          if (validExchange.tickerID == defaultExchange.tickerID) {
            // invoke restrict watchlist dialog for ticker with changed exchange
            this.replacedExchangeWatchlist.add(validExchange.exchange_country);
            this.restrictAccessWatchlist = true;

            validExchange.exchange_countryID =
              defaultExchange.exchange_countryID;
            validExchange.exchange_country = defaultExchange.exchange_country;
            validExchange.partof = true;
          }
        }
      }

      // invoke restrict watchlist dialog for ticker with blocked exchange
      for (const val of validExchanges) {
        if (!val.partof) {
          this.blockedExchangeWatchlist.add(val.exchange_country);
          this.restrictAccessWatchlist = true;
        }
      }

      // remove ticker where it's exchange blocked from the list
      validExchanges = validExchanges.filter((val) => !!val.partof);

      this.watchlist = { ...prewatchlist };
      this.watchlist.companies = [...validExchanges];
      this.currentWatchlistName = this.watchlist.name;
    },
    async loadWatchlist(selectedWatchlist) {
      let prewatchlist = this.$root.clone(selectedWatchlist);

      let validExchanges = [];
      let promises = [];

      // get allowed exchanges for the user
      const response = await axios.post('/dashboard/exchange');
      const allowedExchanges = [...response.data];

      // check selected watchlist against allowed exchanges
      for (const comp of prewatchlist.companies) {
        const isAllowed = allowedExchanges.some(
          ({ exchange_countryID, exchange_country }) =>
            exchange_countryID == comp.exchange_countryID &&
            exchange_country == comp.exchange_country
        );

        comp.partof = true;

        if (!isAllowed) {
          //	if exchange is not part of allowed exchanges then get ticker's exchange_countryID
          promises.push(
            axios.post('/dashboard/regionsByTickerId', {
              tickerId: comp.tickerID
            })
          );
          comp.partof = false;
        }

        validExchanges.push(comp);
      }

      const result = await Promise.all(promises);
      const defaultExchanges = [];

      for (const res of result) {
        let found = false;
        //	check ticker's exchange_countryID against allowed exchange_countryID
        for (let i = 0; i < res.data.length && !found; i++) {
          const { exchange_countryID, exchange_country } =
            allowedExchanges.find(
              ({ exchange_countryID, exchange_country }) =>
                exchange_countryID == res.data[i].exchange_countryID &&
                exchange_country == res.data[i].exchange_country
            ) || {};

          if (exchange_countryID) {
            // need to push explicitly since exchange_countryID, exchange_country  may have same value with existing element in defaultExchanges
            defaultExchanges.push({
              exchange_countryID,
              exchange_country,
              tickerID: res.data[i].tickerID
            });

            found = true;
          }
        }
      }

      this.setWatchlistCompanies(
        defaultExchanges,
        validExchanges,
        prewatchlist
      );
    },

    cleanRestrictWatchlist() {
      this.restrictAccessWatchlist = false;
      this.replacedExchangeWatchlist = new Set();
      this.blockedExchangeWatchlist = new Set();
    },

    refreshWatchlist() {
      if (this.currentWatchlistName) {
        let watchlistTemp = this.$store.state.watchlists.find(
          (x) => x.name == this.currentWatchlistName
        );
        if (watchlistTemp) {
          this.watchlist = this.$root.clone(watchlistTemp);
        }
      }
    },

    removeCompanyFromWatchlist(watchlist) {
      let index = watchlist.companies.findIndex(
        (x) =>
          x.isin == this.selectedItemWatchList.isin &&
          x.tickerID == this.selectedItemWatchList.tickerID &&
          x.exchange_countryID == this.selectedItemWatchList.exchange_countryID
      );
      if (index >= 0) {
        watchlist.companies.splice(index, 1);
        this.$store.commit('saveWatchlist', watchlist);
        this.refreshWatchlist();
      }
    },

    saveWatchlist(name, isUpdate) {
      let watchlistTemp = { name: null, companies: [] };
      if (isUpdate) {
        watchlistTemp = this.$root.clone(
          this.$store.state.watchlists.find((x) => x.name == name)
        );
        watchlistTemp.companies.push(
          this.$root.clone(this.selectedItemWatchList)
        );
      } else {
        let isExist = this.$store.state.watchlists.find((x) => x.name == name);
        if (isExist) {
          this.validWatchlistName = false;
          return;
        }
        watchlistTemp.name = name;
        watchlistTemp.companies.push(
          this.$root.clone(this.selectedItemWatchList)
        );
        this.saveWatchlistDialog = false;
      }
      this.$store.commit('saveWatchlist', watchlistTemp);
      this.refreshWatchlist();
      this.fileNameWatchlist = null;
    },

    showWatchlistDashboardDialog() {
      this.selectedItemWatchList = this.$root.clone(this.selectedItem);
      this.fileNameWatchlist = null;
      this.saveWatchlistDialog = true;
    },

    showWatchlistDialog(item, e) {
      if (e) e.stopPropagation();

      if (item) {
        this.selectedItemWatchList = this.$root.clone(item);
      }

      this.fileNameWatchlist = null;
      this.saveWatchlistDialog = true;
    },

    validateTickerWatchlist(item) {
      return item.companies.find(
        (x) =>
          x.tickerID == this.selectedItemWatchList.tickerID &&
          x.domicile_country == this.selectedItemWatchList.domicile_country &&
          x.exchange_countryID == this.selectedItemWatchList.exchange_countryID
      );
    },

    deleteWatchlist() {
      for (let selectedWatchlist of this.selectedWatchlists) {
        this.$store.commit('deleteWatchlist', selectedWatchlist);
        if (this.watchlist.name && this.watchlist.name == selectedWatchlist) {
          this.watchlist = { name: null, companies: [] };
        }
      }
      this.selectedWatchlists = [];
      this.confirmDeleteWatchlistDialog = false;
    },

    hoverWatchlist(item) {
      let result = '';
      for (let watchlist of this.$store.state.watchlists) {
        if (
          watchlist.companies.find(
            (x) =>
              x.tickerID == item.tickerID &&
              x.exchange_countryID == item.exchange_countryID
          )
        ) {
          result = result + watchlist.name + '<br />';
        }
      }
      return result;
    },

    downloadWatchlist() {
      let watchlists = [];
      let filename = '';

      // Header
      watchlists.push([
        'isin',
        'company_name',
        'domicile_country',
        'exchange_countryID',
        'exchange_country',
        'risk_score',
        'rating',
        'watchlist_name'
      ]);

      for (let selectedWatchlist of this.selectedWatchlists) {
        let item = this.$store.state.watchlists.find(
          (x) => x.name == selectedWatchlist
        );

        if (item) {
          const { companies, name } = item;

          if (companies.length > 0) {
            for (let company of companies) {
              const {
                isin,
                name,
                domicile_country,
                exchange_countryID,
                exchange_country,
                final_score,
                rating
              } = company || {};

              watchlists.push([
                isin,
                name,
                domicile_country,
                exchange_countryID,
                exchange_country,
                final_score,
                rating,
                item.name
              ]);
            }
          } else {
            watchlists.push([null, null, null, null, null, null, name]);
          }
          filename = name;
        }
      }
      let csvContent =
        'data:text/csv;charset=utf-8,' +
        watchlists.map((e) => e.join(',')).join('\n');
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', filename + '.csv');
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "filename.csv".
    },

    onRowClickWatchlist(item) {
      this.selectedTicker.tickerID = item.dataItem.tickerID;
      this.selectedTicker.exchange_countryID = item.dataItem.exchange_countryID;
      // Don't set the year in selected watchlist, use last available year for stock
      this.selectedTicker.year = null;
      this.selectedItem = item.dataItem;
      this.viewTickerDetail(this.selectedTicker, null);
    }
  }
};
