import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
	"en": {
		/* General Message */
		EMPTY_EMAIL: "Please enter your Email Address. This is required.",
		INVALID_EMAIL_FORMAT: "You have entered an invalid Email Address format. Please try again.",
		EMAIL_PASSWORD_EMPTY: "Please enter your Email Address and Password. Both are required.",
		EMPTY_PASSWORD: "Please enter a new password.",
		INVALID_PASSWORD_FORMAT: "Password must be at least 6 characters long.",
		PASSWORD_NOT_MATCH: "These passwords do not match.",
		EMPTY_OTP_CODE: "Please enter a six-digit authentication code. This is required.",
		ACCOUNT_DISABLED: "Your account has been disabled. Contact your admin for details.",
		FILTER_NAME_ALREADY_EXISTS: "This filter name already exists. Please choose another name.",
		WATCHLIST_NAME_ALREADY_EXISTS: "This Watchlist name already exists. Please choose another name.",
		NO_FILTER_SELECTED: "You haven't selected any filter(s) to delete. Please choose which filter you want us to remove.",
		NO_WATCHLIST_SELECTED: "You haven't selected any Watchlist(s) to delete. Please choose which Watchlist you want us to remove.",
		DATA_MATCH_WARNING: "This data is not compatible with the system. This may be because it is was a previous version of the system. You want to continue? pop up confirmation",
		DOWNLOAD_WATCHLIST_LIMITED: "You can download 100 Watchlists at any one time. Please continue.",
		SUCCESS_EMAIL_SEND_FORGOT_PASSWORD: "Your reset password link has been sent to your registered email address. Please check your email",
		SUCCESS_RESEND_CODE: "We have resent the verification code. Please check.",
		DELETE_TENANT_USER_EXIST: "This tenant cannot be deleted since the user is still exists. If you want to delete this tenant, please delete all user associated with this tenant.",
		
		/* Identity Platform Message */
		USER_NOT_FOUND: "This Email Address and/or Password is not recognised. Have you registered?",
		INVALID_PASSWORD: "This Email Address and/or Password is not recognised. Have you registered?",
		WRONG_LOGIN_INFORMATION: "This Email Address and/or Password is not recognised. Have you registered?",
		EMAIL_NOT_FOUND: "A reset Password link has been sent to the Email Address you registered. Please reset your Password from the email you have just received.",
		INVALID_CODE: "This code doesn't seem right. Please check the Two-Factor Authentification message again. Do you need us to resend the code?",
		INVALID_DISPLAY_NAME: "Organization name should not be filled with special characters.",
		INSUFFICIENT_PERMISSION: "You don't have permission to the application.",
		UNDEFINED_ERROR: "Oops! Something went wrong. Please contact your administrator",
		UNAUTHORIZED: "Your session has expired due to inactivity. Please login again using your credentials.",
		MULTIPLE_LOGINS: "Multiple logins detected. Please login again using your credentials to continue.",
		TOO_MANY_ATTEMPTS_TRY_LATER: "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
		PHONE_NUMBER_ALREADY_EXISTS: "The user with the provided phone number already exists.",
		EMAIL_ALREADY_EXISTS: "The user with the provided email already exists",
		INVALID_PHONE_NUMBER: "The format of the phone number is incorrect. Please enter the phone number in a format that can be parsed into E.164 format ([+][country code][phone number]).",
		INVALID_OOB_CODE: "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.",
		OTP_INVALID: "Entered Otp is NOT valid or expired. Please Retry or request a new one!"
	},
}

const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: "es",
	messages
});

export default i18n;
