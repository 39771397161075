<template>
  <k-form
    ref="form"
    :validator="createIssueTicketValidator"
    @submit="createTicket"
  >
    <create-issue-ticket-form />
  </k-form>
</template>

<script>
import { createIssueTicketValidator } from '@/plugins/validator';
import CreateIssueTicketForm from './CreateIssueTicketForm.vue';

export default {
  name: 'create-ticket-issue-form',
  components: { CreateIssueTicketForm },
  created() {
    this.createIssueTicketValidator = createIssueTicketValidator;
  },
  methods: {
    createTicket(formData) {
      if (this.$refs.form.isValid()) {
        const { ticketBody, ticketTitle } = formData || {};
        const ticketUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=lando@transparently.ai&su=${encodeURIComponent(
          ticketTitle
        )}&body=${encodeURIComponent(ticketBody)}`;

        window.open(ticketUrl, '_blank', 'noreferrer');
      }
    }
  }
};
</script>
