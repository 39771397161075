var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-form-element',[(
      _vm.kendoForm.visited &&
      _vm.kendoForm.touched &&
      (_vm.kendoForm.errors.ticketTitle || _vm.kendoForm.errors.ticketBody)
    )?_c('div',{staticClass:"row col-12 error"},[_c('span',[_c('icon-warning')],1),(_vm.kendoForm.errors.ticketTitle && _vm.kendoForm.errors.ticketBody)?_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.kendoForm.errors.summary))}}):[_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.kendoForm.errors.ticketTitle))}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.kendoForm.errors.ticketBody))}})]],2):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center align-items-center"},[_c('h1',[_vm._v("Report an issue")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('k-field',{attrs:{"id":"ticketTitle","name":"ticketTitle","placeholder":"Ticket's title","component":"myTemplate"},scopedSlots:_vm._u([{key:"myTemplate",fn:function(ref){
    var props = ref.props;
return [_c('k-input',_vm._b({attrs:{"maxLength":"100"},on:{"change":props.onChange,"blur":props.onBlur,"focus":props.onFocus}},'k-input',props,false))]}}])})],1),_c('div',{staticClass:"col-12"},[_c('k-field',{attrs:{"id":"ticketBody","name":"ticketBody","placeholder":"Ticket's body","component":"myTemplate"},scopedSlots:_vm._u([{key:"myTemplate",fn:function(ref){
    var props = ref.props;
return [_c('k-textarea',_vm._b({staticStyle:{"height":"auto"},attrs:{"rows":5,"maxLength":"500"},on:{"change":props.onChange,"blur":props.onBlur,"focus":props.onFocus}},'k-textarea',props,false))]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center align-items-center"},[_c('k-button',{attrs:{"id":"createTicket","type":"submit","disabled":!_vm.kendoForm.allowSubmit,"theme-color":"primary"}},[_vm._v(" Submit an issue ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }