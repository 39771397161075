<template>
  <div>
    <div v-if="!isReportFlag">
      <div class="row dashboard__panel dashboard--medium">
        <div class="d-flex justify-content-between mt-2">
          <div class="ps-2">
            <span class="dashboard--color1 pe-2">{{
              ticker.exchange_country
            }}</span>
            <span class="dashboard--color2">{{ ticker.sector_name }}</span>
          </div>
          <div class="dashboard--xsmall">
            <span class="k-link" @click="showTickerInfoPopup"
              ><icon-info></icon-info
            ></span>
          </div>
        </div>
        <div class="row">
          <div class="col ms-2">
            {{ ticker.industryGroup }}
          </div>
        </div>

        <div class="row dashboard--small">
          <div class="col-5">
            <div>
              <span class="dashboard--color1 pe-2">Mkt Value</span>
              {{ ticker.mv == null ? 'N/A' : ticker.mv }} $m
            </div>
            <div>
              <span class="dashboard--color1">Inc</span>
              {{ ticker.incorpCountry }}
              <span class="dashboard--color1">dom</span>
              {{ ticker.domicileCountry }}
              <span class="dashboard--color1">exc</span>
              {{ ticker.exchangeCountry }}
              <span class="dashboard--color1">aud</span> {{ ticker.auditor }}
            </div>
            <div>
              Return from peak (%) <span class="dashboard--color1">Abs</span>
              {{ ticker.rfpAbsolute == null ? 'N/A' : ticker.rfpAbsolute }}
              <span class="dashboard--color1">Rel</span>
              {{ ticker.rfpRelative == null ? 'N/A' : ticker.rfpRelative }}
            </div>
          </div>

          <div class="col-5">
            <div>
              <span class="dashboard--color1">Loss Total</span>
              {{
                ticker.telPessimistic == null ? 'N/A' : ticker.telPessimistic
              }}
              to
              {{ ticker.telOptimistic == null ? 'N/A' : ticker.telOptimistic }}
              $m
            </div>
            <div>
              <span class="dashboard--color1">Equity</span>
              {{ ticker.equityLoss == null ? 'N/A' : ticker.equityLoss }} $m
            </div>
            <div>
              <span class="dashboard--color1">Debt</span>
              {{ ticker.dlPessimistic == null ? 'N/A' : ticker.dlPessimistic }}
              to
              {{ ticker.dlOptimistic == null ? 'N/A' : ticker.dlOptimistic }} $m
            </div>
          </div>

          <div class="col-2">
            <div class="dashboard--color1">Rating</div>
            <div style="font-size: 20px; font-weight: 600">
              {{ ticker.rating == null ? 'N/A' : ticker.rating }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="dashboard__popup dashboard__ticker-info-popup dashboard__panel--overflow"
        v-if="showTIP"
        v-click-outside="onClickOutside"
      >
        <div class="row pb-6">
          <div class="col-1 pt-2">
            <icon-info-popup></icon-info-popup>
          </div>
          <div class="col-6 header d-flex justify-content-start pt-1">
            Stock Summary
          </div>
          <div
            class="col d-flex justify-content-end"
            @click="hideTickerInfoPopup"
          >
            <icon-close2></icon-close2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Inc -</span>
            {{ popupsMap[POPUP_ID.incorporation] }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Mkt Value -</span>
            {{ popupsMap[POPUP_ID.marketCapitalization] }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Dom -</span> {{ popupsMap[POPUP_ID.domicile] }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Exc -</span> {{ popupsMap[POPUP_ID.exchange] }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Aud -</span> {{ popupsMap[POPUP_ID.auditor] }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Abs -</span>
            {{ popupsMap[POPUP_ID.returnAbsolute] }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Rel -</span>
            {{ popupsMap[POPUP_ID.returnRelative] }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Loss Total -</span>
            {{ popupsMap[POPUP_ID.lossTotal] }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Equity -</span>
            {{ popupsMap[POPUP_ID.lossEquity] }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <span class="title">Debt -</span> {{ popupsMap[POPUP_ID.lossDebt] }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="isReportFlag" class="row dashboard--medium">
      <div class="row">
        <div class="col">
          {{ ticker.industryGroup }}
        </div>
      </div>
      <div class="row dashboard--small pe-0">
        <div class="col-7">
          {{ 'Mkt Value ' + (ticker.mv == null ? 'N/A' : ticker.mv) + ' $m' }}
        </div>
        <div class="col-5 pe-0">
          {{
            'Loss Total ' +
            (ticker.telPessimistic == null ? 'N/A' : ticker.telPessimistic) +
            ' to ' +
            (ticker.telOptimistic == null ? 'N/A' : ticker.telOptimistic) +
            ' $m'
          }}
        </div>
      </div>
      <div class="row dashboard--xsmall pe-0">
        <div class="col-7">
          <span>{{
            'Inc ' +
            ticker.incorpCountry +
            ' dom ' +
            ticker.domicileCountry +
            ' exc ' +
            ticker.exchangeCountry +
            ' aud ' +
            ticker.auditor
          }}</span>
        </div>
        <div class="col-5 pe-0">
          {{
            'Equity ' +
            (ticker.equityLoss == null ? 'N/A' : ticker.equityLoss) +
            ' $m'
          }}
        </div>
      </div>
      <div class="row dashboard--xsmall pe-0">
        <div class="col-7">
          {{
            'Return from peak (%) Abs ' +
            (ticker.rfpAbsolute == null ? 'N/A' : ticker.rfpAbsolute) +
            ' Rel ' +
            (ticker.rfpRelative == null ? 'N/A' : ticker.rfpRelative)
          }}
        </div>
        <div class="col-5 pe-0">
          {{
            'Debt ' +
            (ticker.dlPessimistic == null ? 'N/A' : ticker.dlPessimistic) +
            ' to ' +
            (ticker.dlOptimistic == null ? 'N/A' : ticker.dlOptimistic) +
            ' $m'
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { POPUP_ID } from '@/shared';

export default {
  props: ['ticker', 'popupsMap', 'isReportFlag'],
  data() {
    return {
      showTIP: false
    };
  },
  computed: {
    POPUP_ID() {
      return POPUP_ID;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isReportFlag) {
        this.$emit('rendered');
      }
    });
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside() {
      this.hideTickerInfoPopup();
    },
    showTickerInfoPopup() {
      if (!this.showTIP) {
        this.showTIP = true;
      }
    },
    hideTickerInfoPopup() {
      if (this.showTIP) {
        this.showTIP = false;
      }
    }
  }
};
</script>
