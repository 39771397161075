<template>
  <k-form-element>
    <div
      v-if="
        kendoForm.visited &&
        kendoForm.touched &&
        (kendoForm.errors.email || kendoForm.errors.password)
      "
      class="row col-12 error"
    >
      <span><icon-warning /></span>
      <span
        v-if="kendoForm.errors.email && kendoForm.errors.password"
        v-text="$t(kendoForm.errors.summary)"
      ></span>
      <template v-else>
        <span v-text="$t(kendoForm.errors.email)"></span>
        <span v-text="$t(kendoForm.errors.password)"></span>
      </template>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <h1>Welcome to Transparently.AI</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <k-field
          :id="'email'"
          :name="'email'"
          type="email"
          :placeholder="'Email Address'"
          :component="'myTemplate'"
        >
          <template v-slot:myTemplate="{ props }">
            <k-input
              v-bind="props"
              @change="props.onChange"
              @blur="props.onBlur"
              @focus="props.onFocus"
            />
          </template>
        </k-field>
      </div>
      <div class="col-12">
        <k-field
          :id="'password'"
          :name="'password'"
          type="password"
          :placeholder="'Password'"
          :component="'myTemplate'"
        >
          <template v-slot:myTemplate="{ props }">
            <k-input
              v-bind="props"
              @change="props.onChange"
              @blur="props.onBlur"
              @focus="props.onFocus"
            />
          </template>
        </k-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <router-link
          class="k-button-link k-button-link-base"
          to="/forgotPassword"
          >Forgot Password?</router-link
        >
        <k-button
          id="login"
          type="submit"
          :disabled="!kendoForm.allowSubmit"
          theme-color="primary"
        >
          Login
        </k-button>
      </div>
    </div>
  </k-form-element>
</template>

<script>
export default {
  inject: {
    kendoForm: { default: {} }
  }
};
</script>
