var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-form-element',[(
      _vm.kendoForm.visited &&
      _vm.kendoForm.touched &&
      (_vm.kendoForm.errors.email || _vm.kendoForm.errors.password)
    )?_c('div',{staticClass:"row col-12 error"},[_c('span',[_c('icon-warning')],1),(_vm.kendoForm.errors.email && _vm.kendoForm.errors.password)?_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.kendoForm.errors.summary))}}):[_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.kendoForm.errors.email))}}),_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.kendoForm.errors.password))}})]],2):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',[_vm._v("Welcome to Transparently.AI")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('k-field',{attrs:{"id":'email',"name":'email',"type":"email","placeholder":'Email Address',"component":'myTemplate'},scopedSlots:_vm._u([{key:"myTemplate",fn:function(ref){
    var props = ref.props;
return [_c('k-input',_vm._b({on:{"change":props.onChange,"blur":props.onBlur,"focus":props.onFocus}},'k-input',props,false))]}}])})],1),_c('div',{staticClass:"col-12"},[_c('k-field',{attrs:{"id":'password',"name":'password',"type":"password","placeholder":'Password',"component":'myTemplate'},scopedSlots:_vm._u([{key:"myTemplate",fn:function(ref){
    var props = ref.props;
return [_c('k-input',_vm._b({on:{"change":props.onChange,"blur":props.onBlur,"focus":props.onFocus}},'k-input',props,false))]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-between align-items-center"},[_c('router-link',{staticClass:"k-button-link k-button-link-base",attrs:{"to":"/forgotPassword"}},[_vm._v("Forgot Password?")]),_c('k-button',{attrs:{"id":"login","type":"submit","disabled":!_vm.kendoForm.allowSubmit,"theme-color":"primary"}},[_vm._v(" Login ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }