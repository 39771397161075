<template>
	<div>
		<div v-if="$window.location.origin.includes('test')" class="custom-banner" style="background-color: #03b3b2; color: white">TEST</div>
		<div v-if="$window.location.origin.includes('localhost')" class="custom-banner" style="background-color: #82ba00; color: white">DEVELOPMENT</div>
		<router-view></router-view>

		<k-dialog class="ta-notification" v-if="$store.state.notification.show" :title="' '" @close="hideNotification">
			<p v-if="$store.state.notification.content.message" v-html="$t($store.state.notification.content.message)"></p>
			<p v-else v-html="$t($store.state.notification.content)"></p>
			<k-button :theme-color="'secondary'" :fill-mode="'outline'" @click="hideNotification">Close</k-button>
		</k-dialog>

		<!-- <div v-if="false" class="loader"> -->
		<div v-if="($root.loader.value && !$store.state.splashScreen && $root.loader.dynamicFilter) || isLoading" class="loader">
			<div class="k-overlay"></div>
			<img src="@/assets/img/loader.svg" alt="" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters('fastspring', [
			'isLoading',
		]),
	},
	methods: {
		hideNotification() {
			if (this.$store.state.notification.code === 401) {
				this.$store.commit("logout");
				this.$router.push("/");
			}
      if (this.$store.state.notification.urlPath) {
        this.$router.push(this.$store.state.notification.urlPath);
      }
			this.$store.commit("hideNotification");
		}
	}
};
</script>
