export default {
  methods: {
    handleShowErrorNotification(error) {
      const DEFAULT_MESSAGE = 'An unknown error has occurred';

      let notification = { title: 'Unknown error', content: '' };

      if (!error.response) return;

      notification.title = 'Notification (' + error.response.status + ')';

      if (error.response.status === 401) {
        this.$store.dispatch('logout').then(() => {
          this.$router.push('/');
        });
      }

      const {
        errorCode,
        message,
        error: responseError
      } = error.response.data || {};
      const errorMessage = errorCode || message;
      const errorDataMessage = responseError ? responseError.message : '';

      notification.content = this.$te(errorMessage)
        ? errorMessage
        : errorDataMessage || error.response.data || DEFAULT_MESSAGE;

      this.$store.commit('showNotification', notification);
    }
  }
};
