export const displayPrice = (value, isRefund, currency) => {
  let formattedValue = 0;

  if (typeof value === 'string') {
    formattedValue = !isRefund
      ? Number(value)
      : Number(value.replaceAll('-', ''));
  } else {
    formattedValue = !isRefund ? value : Math.abs(value);
  }

  return `${currency || ''}${formattedValue.toFixed(2)}`;
};
