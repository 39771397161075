<template>
  <k-form-element>
    <div
      v-if="
        kendoForm.visited &&
        kendoForm.touched &&
        (kendoForm.errors.ticketTitle || kendoForm.errors.ticketBody)
      "
      class="row col-12 error"
    >
      <span><icon-warning /></span>
      <span
        v-if="kendoForm.errors.ticketTitle && kendoForm.errors.ticketBody"
        v-text="$t(kendoForm.errors.summary)"
      ></span>
      <template v-else>
        <span v-text="$t(kendoForm.errors.ticketTitle)"></span>
        <span v-text="$t(kendoForm.errors.ticketBody)"></span>
      </template>
    </div>
    <div v-else class="row">
      <div class="col-12 d-flex justify-content-center align-items-center">
        <h1>Report an issue</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <k-field
          id="ticketTitle"
          name="ticketTitle"
          placeholder="Ticket's title"
          component="myTemplate"
        >
          <template v-slot:myTemplate="{ props }">
            <k-input
              v-bind="props"
              @change="props.onChange"
              @blur="props.onBlur"
              @focus="props.onFocus"
              maxLength="100"
            />
          </template>
        </k-field>
      </div>
      <div class="col-12">
        <k-field
          id="ticketBody"
          name="ticketBody"
          placeholder="Ticket's body"
          component="myTemplate"
        >
          <template v-slot:myTemplate="{ props }">
            <k-textarea
              v-bind="props"
              @change="props.onChange"
              @blur="props.onBlur"
              @focus="props.onFocus"
              :rows="5"
              maxLength="500"
              style="height: auto"
            />
          </template>
        </k-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-center align-items-center">
        <k-button
          id="createTicket"
          type="submit"
          :disabled="!kendoForm.allowSubmit"
          theme-color="primary"
        >
          Submit an issue
        </k-button>
      </div>
    </div>
  </k-form-element>
</template>

<script>
export default {
  inject: {
    kendoForm: { default: {} }
  }
};
</script>
