<template>
	<k-form-element>
		<div v-if="kendoForm.visited && kendoForm.touched && kendoForm.errors.email" class="row col-12 error">
			<span><icon-warning /></span>
			<span v-text="$t(kendoForm.errors.email)"></span>
		</div>
		<div v-else class="row">
			<div class="col-12">
				<p class="mfa-info">Send reset link to your registered email address</p>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<k-field :name="'email'" type="email" :placeholder="'Email Address'" :component="'myTemplate'">
					<template v-slot:myTemplate="{ props }">
						<k-input v-bind="props" @change="props.onChange" @blur="props.onBlur" @focus="props.onFocus" />
					</template>
				</k-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 d-flex justify-content-between align-items-center">
				<router-link class="k-button-link k-button-link-base" to="/">Login</router-link>
				<k-button type="submit" :disabled="!kendoForm.allowSubmit" theme-color="primary"> Send </k-button>
			</div>
		</div>
	</k-form-element>
</template>

<script>
export default {
	inject: {
		kendoForm: { default: {} }
	}
};
</script>