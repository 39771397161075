import axios from 'axios';
import {
  getDropdownRegions,
  processBundle,
  checkIsSubscribed,
  removeUnselectedRegionIds,
  SUBSCRIPTION_STATE,
  buildUserSubscriptionInformation
} from '@/shared/utils/store-fastspring';
import {
  checkIsSubscriptionCanceled,
  checkIsUserHaveNoSeat,
  checkIsUserSubscriptionExpired,
  checkIsUserUsingTrialSubscription,
  checkIsUserPaidForSubscription
} from '@/shared/utils/subscription';

const state = () => ({
  builder: null,
  bundles: [],
  bundleRegions: [],
  costlyRegions: [],
  dropdownBundleRegions: [],
  dropdownCostlyRegions: [],
  selectedBundle: null,
  extraSeatCount: 0,
  subscription: null,
  fastspringSubscriptionData: null,
  isLoading: false,
  isFirstTenantAdminLogin: false,
  isPaymentCompleted: false,
  userCount: 0,
  isOrderPreview: false,
  totalPrice: null,
  totalPriceValue: 0,
  tax: null,
  cancelSubscriptionNoticeable: true,
  trialSubscriptionEndNoticeable: true
});

const mutations = {
  INITIALIZE(state, fastspring) {
    state.builder = fastspring.builder;
  },
  SET_SELECTED_BUNDLE(state, bundle) {
    state.selectedBundle = bundle;
  },
  SET_EXTRA_SEAT_COUNT(state, count) {
    state.extraSeatCount = count;
  },
  SET_IS_FIRST_TENANT_ADMIN_LOGIN(state, value) {
    state.isFirstTenantAdminLogin = value;
  },
  SET_IS_ORDER_PREVIEW(state, value) {
    state.isOrderPreview = value;
  },
  SET_TOTAL_PRICE(state, value) {
    state.totalPrice = value;
  },
  SET_TOTAL_PRICE_VALUE(state, value) {
    state.totalPriceValue = value;
  },
  SET_TAX_PRICE(state, value) {
    state.tax = value;
  },
  SET_SELECTED_REGIONS(state, value) {
    state.dropdownBundleRegions.forEach(removeUnselectedRegionIds(value));
    state.dropdownCostlyRegions.forEach(removeUnselectedRegionIds(value));
  },
  INITIALIZE_SELECTED_BUNDLE(state) {
    if (!state.subscription) {
      return;
    }

    const selectedBundleId = state.subscription.productId;

    if (selectedBundleId) {
      state.selectedBundle = state.bundles.find(
        (t) => t.pid === selectedBundleId
      );

      if (state.subscription.seatCount) {
        state.extraSeatCount =
          state.subscription.seatCount -
          Number(state.selectedBundle ? state.selectedBundle.seatCount : 0);
      }
    }
  },
  INITIALIZE_SELECTED_COUNTRIES(state) {
    const tenant = state.subscription.tenant;

    if (!tenant) {
      return;
    }

    const regionList = JSON.parse(tenant.region || '[]');
    const regionProductIds = JSON.parse(
      state.subscription.regionProductIds || '[]'
    );

    state.dropdownBundleRegions.forEach((region) => {
      region.countries.forEach((country) => {
        if (regionProductIds.includes(country.id)) {
          country.selected = true;
          country.disabled = true;
        }
      });
    });

    state.dropdownCostlyRegions.forEach((region) => {
      region.countries.forEach((country) => {
        if (
          regionProductIds.includes(country.id) ||
          regionList.includes(country.bigqueryRegionId)
        ) {
          country.selected = true;
        }

        //If the product id is not the same, but the region id match.
        //Then it should be the countries that attached with the bundle when user first create their subscription.
        //We do not allow them to freely change those countries because the price is 0$ for Tier 4.
        if (
          !regionProductIds.includes(country.id) &&
          regionList.includes(country.bigqueryRegionId)
        ) {
          const regionProductId = regionProductIds.find((regionId) =>
            regionId?.includes(country.id)
          );

          if (regionProductId?.includes('-bundle')) country.disabled = true;
        }
      });
    });
  },
  CLEAR_SELECTED_REGIONS(state) {
    state.dropdownCostlyRegions
      .flatMap((t) => t.countries)
      .forEach((t) => (t.selected = false));
    state.dropdownBundleRegions
      .flatMap((t) => t.countries)
      .forEach((t) => (t.selected = false));
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
  SET_FASTSPRING_SUBSCRIPTION(state, subscription) {
    state.fastspringSubscriptionData = subscription;
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_IS_PAYMENT_COMPLETED(state, value) {
    state.isPaymentCompleted = value;
  },
  CANCEL_SUBSCRIPTION_NOTICEABLE(state, value) {
    state.cancelSubscriptionNoticeable = value;
  },
  TRIAL_SUBSCRIPTION_END_NOTICEABLE(state, value) {
    state.trialSubscriptionEndNoticeable = value;
  },
  DATA_CHANGED(state, data) {
    if (!data) {
      return;
    }

    if (!data.groups) {
      return;
    }

    data.groups.forEach((group) => {
      if (group.display === 'Bundles') {
        state.bundles = group.items;
        state.bundles.forEach((t) => processBundle(state, t));

        if (state.selectedBundle) {
          state.selectedBundle = state.bundles.find(
            (t) => t.pid === state.selectedBundle.pid
          );
        }
      }
    });
  }
};

const actions = {
  updateCart({ rootState, state, getters }, options) {
    const { email = '', name = '' } = rootState.principal;
    const { isSubscribed, selectedRegions } = getters || {};
    const { subscription, selectedBundle, extraSeatCount, builder } =
      state || {};

    const products = [
      {
        path: isSubscribed ? subscription.productId : selectedBundle.pid,
        quantity: extraSeatCount + 1
      },
      ...selectedRegions.map((t) => ({
        path: t.id,
        quantity: extraSeatCount + 1
      }))
    ];

    if (extraSeatCount && selectedBundle?.extraSeat) {
      products.push({
        path: selectedBundle.extraSeat.path,
        quantity: extraSeatCount
      });
    }

    const names = name.split(' ');
    const session = {
      reset: true,
      products,
      paymentContact: {
        email,
        firstName: names[0],
        lastName: names.pop()
      }
    };

    return new Promise((resolve, reject) => {
      if (options?.skipUpdateSession) {
        resolve({
          order: {},
          userSubscriptionInformation: buildUserSubscriptionInformation(
            {
              rootState,
              state,
              getters
            },
            state.selectedBundle.subscription
          )
        });

        return;
      }

      if (!builder) {
        reject(new Error('Builder not initialized'));
        return;
      }

      state.builder.push(session, (order) => {
        resolve({
          order,
          userSubscriptionInformation: buildUserSubscriptionInformation(
            {
              rootState,
              state,
              getters
            },
            state.selectedBundle.subscription
          )
        });
      });
    });
  },
  updateSubscription({ rootState, state, getters }, userSubscriptionDetail) {
    const requestData = buildUserSubscriptionInformation(
      { rootState, state, getters },
      userSubscriptionDetail
    );

    state.isLoading = true;

    axios
      .post(`payment/updateSubscription`, requestData)
      .then((result) => {
        if (result.status === 200 && result.data) {
          state.subscription = result.data;

          if (result?.data?.fastspringSubscriptionId) {
            window
              .open(
                `https://www.transparently.ai/thank-you-for-upgrade?orderId=${result.data.fastspringSubscriptionId}`,
                '_blank'
              )
              .focus();
          }

          if (!userSubscriptionDetail.isPaymentUpdate) {
            state.isOrderPreview = true;
          }
        }
      })
      .finally(() => {
        state.isLoading = false;
      });
  },
  onPaymentSuccess({ rootState, state, getters }, data) {
    if (!data) {
      return;
    }

    if (!data?.items?.length) {
      return;
    }

    const subscription = data.items.find((t) => t.driver.type === 'cross-sell');
    const { tenantId } = rootState.principal;
    const {
      product,
      attributes,
      subscription: fastspringSubscriptionId
    } = subscription;

    const { nextChargeDate, nextChargeDateValue, instructions } =
      state.selectedBundle.subscription;
    const [instruction] = instructions || [];
    const { periodEndDateValue, priceTotal, priceTotalValue } =
      instruction || {};
    const requestData = {
      companyId: tenantId,
      productId: product,
      seatCount: +attributes.seatCount + state.extraSeatCount,
      regionCount: getters.selectedRegions.length,
      fastspringSubscriptionId,
      nextChargeDate,
      nextChargeDateValue,
      nextChargeTotal: priceTotal,
      nextChargeTotalValue: priceTotalValue,
      regionIds: getters.selectedRegions
        .map((t) => t.bigqueryRegionId)
        .filter((t) => t),
      regionProductIds: getters.selectedRegions
        .map((t) => t.id)
        .filter((t) => t),
      expiredTime: periodEndDateValue,
      selectAllRegions: false
    };

    state.isLoading = true;

    axios
      .post(`payment/addSubscription`, requestData)
      .then((result) => {
        if (result.status === 200 && result.data) {
          state.subscription = result.data;
          state.isPaymentCompleted = true;

          const { fastspringSubscriptionId } = result.data || {};
          if (fastspringSubscriptionId) {
            axios
              .get(`/payment/subscriptions/${fastspringSubscriptionId}`)
              .then((response) => {
                if (response.status === 200 && response.data) {
                  state.fastspringSubscriptionData = response.data.data;

                  if (data.reference) {
                    let url = '';

                    if (checkIsUserUsingTrialSubscription(state.subscription)) {
                      url =
                        'https://www.transparently.ai/thank-you-for-free-trial';
                    } else {
                      url =
                        'https://www.transparently.ai/thank-you-for-purchase';
                    }
                    window
                      .open(`${url}?orderId=${data.reference}`, '_blank')
                      .focus();
                  }
                }
              });
          }
        }
      })
      .finally(() => {
        state.isLoading = false;
      });
  },
  onUpgradeSubscriptionPlan({ state }) {
    if (!state.selectedBundle.subscription) {
      return;
    }

    const { product, subscription } = state.selectedBundle || {};
    const {
      nextChargeDate,
      nextChargeDateValue,
      nextChargeTotal,
      nextChargeTotalValue
    } = subscription || {};

    const requestData = {
      productId: product,
      seatCount: 0,
      regionCount: 0,
      fastspringSubscriptionId: '',
      nextChargeDate,
      nextChargeDateValue,
      nextChargeTotal,
      nextChargeTotalValue,
      regionProductIds: []
    };

    state.subscription = {
      ...state.subscription,
      tenant: {
        ...state.subscription.tenant,
        region: []
      },
      ...requestData
    };
    state.isPaymentCompleted = false;
  },
  agreeSubscriptionTerms({ state }) {
    const requestData = {
      companyId: state.subscription.tenant.companyId,
      agreedTerms: true,
      selectAllRegions: false
    };

    state.isLoading = true;

    return new Promise((resolve) => {
      axios
        .post(`payment/agreeSubscriptionTerms`, requestData)
        .then((result) => {
          if (result.status === 200 && result.data) {
            state.subscription = result.data;
            resolve();
          }
        })
        .finally(() => {
          state.isLoading = false;
        });
    });
  },
  cancelSubscription({ state }, data) {
    state.isLoading = true;

    return new Promise((resolve) => {
      axios
        .post('payment/cancelSubscription', {
          cancellationReason: data.reasons,
          otherReason: data.otherReason
        })
        .then((result) => {
          if (result.status === 200 && result.data) {
            resolve();
          }
        })
        .finally(() => {
          state.isLoading = false;
        });
    });
  },
  resumeSubscription({ state }) {
    state.isLoading = true;

    return new Promise((resolve) => {
      axios
        .post('payment/resumeSubscription')
        .then((result) => {
          if (result.status === 200 && result.data) {
            resolve();
          }
        })
        .finally(() => {
          state.isLoading = false;
        });
    });
  },

  async getAccountListOfUser({ state, rootState }) {
    try {
      const [fireBaseAccountList, accountList] = await Promise.all([
        axios.post('/person/list', { tenantId: rootState.principal.tenantId }),
        axios.get('/tenant/admin/user-by-tenant')
      ]);

      const accounts = [];

      for (const firebaseAccount of fireBaseAccountList.data.listUser) {
        const account = accountList.data.data.find(
          ({ uid }) => uid === firebaseAccount.uid
        );

        accounts.push({
          ...account,
          ...firebaseAccount
        });
      }

      state.userCount = accounts.length;

      return Promise.resolve(accounts);
    } finally {
      state.isLoading = false;
    }
  }
};

const getters = {
  bundles(state) {
    return state.bundles;
  },
  selectedBundle(state) {
    return state.selectedBundle;
  },
  extraSeatCount(state) {
    return state.extraSeatCount;
  },
  dropdownRegions(state) {
    return getDropdownRegions(state);
  },
  selectedRegions(state) {
    const bundleRegions = state.dropdownBundleRegions
      .flatMap((t) => t.countries)
      .filter((t) => t.selected);
    const selectedIds = bundleRegions.map((t) => t.bigqueryRegionId);
    const costlyRegions = state.dropdownCostlyRegions
      .flatMap((t) => t.countries)
      .filter((t) => t.selected && !selectedIds.includes(t.bigqueryRegionId));
    return bundleRegions.concat(costlyRegions);
  },
  subscription(state) {
    return state.subscription;
  },
  isSubscribed(state) {
    return checkIsSubscribed(state);
  },
  isLoading(state) {
    return state.isLoading;
  },
  showPaymentForm(state, getter) {
    if (
      getter.selectedRegions &&
      state.selectedBundle.regionCount === getter.selectedRegions.length
    ) {
      return true;
    }

    return getter.isSubscribed;
  },
  isPaymentCompleted(state) {
    return state.isPaymentCompleted;
  },
  isOrderPreview(state) {
    return state.isOrderPreview;
  },
  userCount(state) {
    return state.userCount;
  },
  totalPrice(state) {
    if (!state.totalPrice) {
      if (state.subscription.nextChargeTotalValue)
        return state?.subscription?.nextChargeTotal;
      else return state?.selectedBundle?.subscription?.nextChargeTotal;
    }
    return state.totalPrice;
  },
  tax(state) {
    return state.tax;
  },
  isCancelingSubscription(state) {
    if (
      !state.subscription.fastspringSubscriptionId ||
      checkIsUserUsingTrialSubscription(state.subscription)
    )
      return false;

    return (
      state.fastspringSubscriptionData &&
      state.fastspringSubscriptionData.state !== SUBSCRIPTION_STATE.ACTIVE
    );
  },
  isSubscriptionAvailable(state) {
    if (!state.subscription || !state.fastspringSubscriptionData) return false;

    if (checkIsUserSubscriptionExpired(state.subscription)) return false;

    if (checkIsUserHaveNoSeat(state.subscription)) return false;

    if (
      checkIsSubscriptionCanceled(
        state.subscription,
        state.fastspringSubscriptionData
      )
    )
      return false;

    if (!checkIsUserPaidForSubscription(state.subscription)) return false;

    return true;
  },
  cancelSubscriptionNoticeable(state) {
    return state.cancelSubscriptionNoticeable;
  },
  trialSubscriptionEndNoticeable(state) {
    return state.trialSubscriptionEndNoticeable;
  }
};

const fastspring = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default fastspring;
