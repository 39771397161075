<template>
  <k-dialog
    v-if="isModalShowing"
    :title="' '"
    @close="$emit('close')"
    :class="className"
  >
    <div class="row mb-3">
      <div
        v-if="!!title"
        class="col-12 mb-1 text-center title"
        v-html="title"
      ></div>

      <div class="col-10 mx-auto">
        <p
          :style="{
            textAlign: 'center'
          }"
        >
          <slot name="description" />
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <slot name="action" />
      </div>
    </div>
  </k-dialog>
</template>

<script>
export default {
  name: 'subscription-modal',
  props: {
    isModalShowing: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    title: {
      type: String,
      default: function () {
        return ' ';
      }
    },
    description: String,
    className: String
  }
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
</style>
