export const POPUP_ID = {
  gics: 'pop_gics_s',
  gicsIndustry: 'pop_gics_i',
  year: 'pop_year',
  domicile: 'pop_dom',
  auditor: 'pop_aud',
  incorporation: 'pop_inc',
  exchange: 'pop_ex',
  marketCapitalization: 'pop_mcap',
  watch: 'pop_watch',
  returnRelative: 'pop_ret_rel',
  returnAbsolute: 'pop_ret_abs',
  history: 'pop_history',
  score: 'pop_score',
  lossTotal: 'pop_loss_total',
  lossDebt: 'pop_loss_debt',
  lossEquity: 'pop_loss_equity',
  tick: 'pop_tick',
  distributionTotal: 'pop_distr_total',
  distributionSector: 'pop_distr_sector',
};
