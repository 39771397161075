<template>
  <div>
    <k-button
      class="actions-button"
      theme-color="secondary"
      @click="showNavigationPopup = true"
      ref="button"
      v-click-outside="closeNavigationPopup"
    >
      <icon-dots />
    </k-button>

    <k-popup
      :anchor="'button'"
      :show="showNavigationPopup"
      :popup-class="'popup-content'"
    >
      <slot />
    </k-popup>
  </div>
</template>

<script>
export default {
  name: 'actionsButton',
  data() {
    return {
      showNavigationPopup: false
    };
  },
  methods: {
    closeNavigationPopup() {
      this.showNavigationPopup = false;
    }
  }
};
</script>

<style scoped lang="scss">
.actions-button {
  display: flex;
  width: fit-content;
  min-width: 0;

  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
}
</style>
