<template>
  <div class="header">
    <div class="container d-flex justify-content-between align-items-center">
      <icon-logo-header class="header__img" />

      <div>
        <k-button
          v-if="isSubscribed"
          class="header__button me-3"
          @click="goTo('/dashboard')"
        >
          Dashboard
        </k-button>
        <k-button class="header__button me-3" @click="goTo('/manageUsers')">
          Manage Users
        </k-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters('fastspring', ['isSubscribed'])
  },
  methods: {
    ...mapMutations('fastspring', [
      'CLEAR_SELECTED_REGIONS',
      'SET_IS_PAYMENT_COMPLETED',
      'SET_IS_ORDER_PREVIEW'
    ]),
    goTo(url) {
      this.$router.push(url);
      this.SET_IS_PAYMENT_COMPLETED(false);
      this.SET_IS_ORDER_PREVIEW(false);
      this.CLEAR_SELECTED_REGIONS();
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #13151a;
  padding: 20px 0;

  &__img {
    width: 204px;
    height: 72px;
  }

  &__button {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 14px 22px 14px 24px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    height: 48px;
  }
}
</style>
