<template>
  <div class="layout layout--public">
    <div class="layout__body">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <icon-logo />
          </div>
        </div>
        <k-form
          v-if="!isSuccess"
          ref="form"
          :validator="resetPasswordFormValidator"
          @submit="resetPassword"
        >
          <reset-password-form />
        </k-form>
        <div v-else class="row">
          <div class="col-12 mfa-info">
            <span class="k-icon k-i-check-circle k-icon-lg text-success"></span>
          </div>
          <div class="col-12 mfa-info">
            <h1>
              Password has been reset.
              <router-link class="k-button-link k-button-link-primary" to="/"
                >Login</router-link
              >
            </h1>
          </div>
        </div>
      </div>
    </div>
    <vue-recaptcha
      hidden="true"
      ref="recaptcha"
      @verify="onCaptchaVerified"
      @expired="resetCaptcha"
      @error="errorMethod"
      size="invisible"
      :sitekey="$recaptchaSiteKey"
    >
    </vue-recaptcha>
    <k-notification
      ref="popupNotification"
      :allow-hide-after="1000"
      :auto-hide-after="7000"
      :button="true"
      :hide-on-click="true"
      class="mt-2"
    >
    </k-notification>
  </div>
</template>

<script>
import resetPasswordForm from './resetPasswordForm';
import { resetPasswordFormValidator } from '@/plugins/validator';
import axios from 'axios';
import loginRedirectMixin from '@/shared/mixins/loginRedirect';

export default {
  components: {
    resetPasswordForm
  },
  mixins: [loginRedirectMixin],
  data: function () {
    return {
      dialogshow: false,
      isSuccess: false,
      isUsingMagicLink: false,
      entity: {
        newPassword: null
      },
      resetPasswordFormValidator
    };
  },
  mounted() {
    this.popupNotificationWidget = this.$refs.popupNotification.kendoWidget();

    this.checkForMagicLink();
  },
  methods: {
    checkForMagicLink() {
      const magicLinkToken = this.$route.query.token;

      if (magicLinkToken) {
        this.SET_IS_FIRST_TENANT_ADMIN_LOGIN(true);
        this.isUsingMagicLink = true;
      }
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
    errorMethod() {
      console.log('reCaptcha Error');
    },
    onCaptchaVerified(token) {
      this.resetCaptcha();
      this.$store
        .dispatch('setNewPassword', {
          token: this.$route.query.token,
          newPassword: this.entity.newPassword,
          tenantId: this.$route.query.tenantId,
          recaptchaToken: token
        })
        .then(this.redirectAfterLogin);
    },
    resetPassword(dataItem) {
      if (this.$refs.form.isValid()) {
        if (this.isUsingMagicLink) {
          if (!this.$refs.recaptcha.widgetId) this.resetCaptcha();
          this.$refs.recaptcha.execute();
          this.entity = dataItem;
        } else {
          var vm = this;
          axios
            .post('/auth/resetPassword', {
              oobCode: this.$route.query.oobCode,
              newPassword: dataItem.newPassword,
              tenantId: this.$route.query.tenantId
            })
            .then((response) => {
              if (response.status == 200) {
                this.isSuccess = true;
                this.email = dataItem.email;
              } else if (response.status == 204) {
                vm.popupNotificationWidget.show(
                  'Passwords need to be : At least 8 characters and at most 128 characters, at least 1 lowercase (a-z), at least 1 uppercase (A-Z), at least one digit (0-9), and at least 1 special character (punctuation)',
                  'error'
                );
              }
            });
        }
      }
    }
  }
};
</script>
