import axios from 'axios';

export default {
  data: function () {
    return { contactAdmin: '' };
  },
  mounted() {
    this.getContactAdmin();
  },
  methods: {
    async getContactAdmin() {
      const response = await axios.post('/setting/contactAdmin');

      this.contactAdmin = response.data.value;
    }
  }
};
