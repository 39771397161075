// import { parseDomain, ParseResultType } from 'parse-domain';
import Vue from 'vue';

export const setLucaCookies = (accessToken, lucaAccessible) => {
  // const parseResult = parseDomain(window.location.hostname);

  // if (parseResult.type === ParseResultType.Listed) {
  //   const { domain, topLevelDomains } = parseResult;
  const now = new Date();
  now.setTime(now.getTime() + 24 * 3600 * 1000);

  //   Vue.$cookies.set(
  //     'access_token',
  //     accessToken,
  //     now.toUTCString(),
  //     '/',
  //     `.${domain}.${topLevelDomains.join('.')}`
  //   );

  //   if (lucaAccessible) {
  //     Vue.$cookies.set(
  //       'lucaAccessible',
  //       lucaAccessible,
  //       now.toUTCString(),
  //       '/',
  //       `.${domain}.${topLevelDomains.join('.')}`
  //     );
  //   } else {
  //     Vue.$cookies.remove('lucaAccessible');
  //   }
  // }

  Vue.$cookies.set(
    'access_token',
    accessToken,
    now.toUTCString(),
    '/',
    `.transparently.ai`
  );

  if (lucaAccessible) {
    Vue.$cookies.set(
      'lucaAccessible',
      lucaAccessible,
      now.toUTCString(),
      '/',
      `.transparently.ai`
    );
  } else {
    Vue.$cookies.remove('lucaAccessible');
  }
};

export const removeLucaCookies = () => {
  Vue.$cookies.remove('access_token');
  Vue.$cookies.remove('lucaAccessible');
};

export const getUrl = (route) => {
  // const parseResult = parseDomain(window.location.hostname);

  // // check if the domain is listed in the public suffix list
  // if (parseResult.type === ParseResultType.Listed) {
  //   const { domain, topLevelDomains, subDomains } = parseResult;

  //   return `https://${
  //     subDomains && subDomains.length > 0 && subDomains.join('.') + '.'
  //   }${domain}.${topLevelDomains.join('.')}/${route}`;
  // }

  return `${window.location.origin}/${route}`;
};
