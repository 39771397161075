<template>
  <kinput
    :valid="valid"
    :id="id"
    :type="type"
    :value="value"
    :disabled="disabled"
    :readonly="readonly"
    :placeholder="placeholder"
    :maxlength="maxLength"
    @input="handleChange"
    @blur="handleBlur"
    @focus="handleFocus"
  />
</template>
<script>
import { Input } from '@progress/kendo-vue-inputs';
export default {
  props: {
    optional: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    placeholder: String,
    touched: Boolean,
    label: String,
    validationMessage: String,
    hint: String,
    id: String,
    valid: Boolean,
    type: String,
    value: {
      type: String,
      default: ''
    },
    maxLength: String
  },
  components: {
    kinput: Input
  },
  computed: {
    showValidationMessage() {
      return this.$props.touched && this.$props.validationMessage;
    },
    showHint() {
      return !this.showValidationMessage && this.$props.hint;
    },
    hintId() {
      return this.showHint ? `${this.$props.id}_hint` : '';
    },
    errorId() {
      return this.showValidationMessage ? `${this.$props.id}_error` : '';
    }
  },
  emits: {
    change: null,
    blur: null,
    focus: null
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e);
    },
    handleBlur(e) {
      this.$emit('blur', e);
    },
    handleFocus(e) {
      this.$emit('focus', e);
    }
  }
};
</script>
