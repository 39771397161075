<template>
  <div class="seats-form">
    <div
      v-if="isUserUpdatingSubscriptionDetails"
      class="back-button"
      @click="$router.go(-1)"
    >
      <img src="@/assets/img/arrow-left.svg" alt="" />
      Back
    </div>

    <template v-if="isSubscribed && !isModifying && !isPaymentCompleted">
      <div class="current-subscription">
        <div class="current-subscription__column-titles">
          <div class="current-subscription__column-title">Number of seats</div>
          <template>
            <div
              v-if="!isCancelingSubscription"
              class="current-subscription__column-title"
            >
              Next billing date
            </div>
            <div v-else class="current-subscription__column-title">
              Your subscription will be active until
            </div>
          </template>
          <div
            v-if="!isCancelingSubscription"
            class="current-subscription__column-title"
          >
            Next billing amount
          </div>
        </div>

        <div class="current-subscription__body">
          <div class="current-subscription__column">
            {{
              subscription.seatCount +
              ' ' +
              (subscription.seatCount > 1 ? 'Seats' : 'Seat')
            }}
          </div>
          <div class="current-subscription__column">
            <div>{{ subscription.nextChargeDate }}</div>
            <div
              v-if="!isCancelingSubscription"
              class="current-subscription__column--grayed"
            >
              <span v-if="isUserUsingAnnuallySubscription">
                Renews every year.
              </span>
              <span v-else>Renews every month.</span>
            </div>
          </div>
          <div
            v-if="!isCancelingSubscription"
            class="current-subscription__column current-subscription__column--green"
          >
            {{ totalPrice }}
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div
        v-if="isUserUpgradingSubscription"
        class="back-button"
        @click="goTo('/subscriptionPlans')"
      >
        <img src="@/assets/img/arrow-left.svg" alt="" />
        Back to Select Subscription Plans
      </div>

      <div class="seats-form-title">Details</div>
      <div class="seats-form-body">
        <div class="number-of-seats-form">
          <div class="instruction-field">
            <div class="instruction-title">Seats</div>
            <div class="instruction-text" v-if="!!instruction">
              {{ instruction }}
            </div>
            <div v-if="isAccountsListBiggerThanOwnedSeatsNumber" class="mt-3">
              <select-user-table :tooltip="tooltip" />
            </div>
          </div>

          <div class="seat-count-field">
            <div
              class="seat-select-container"
              v-if="isSubscribed && !isPaymentCompleted"
            >
              <k-select
                v-model="count"
                @change="(event) => onChange(event.value)"
                @open="onOpen"
                @close="onClose"
                :data="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, '10+']"
                :valid="true"
                class="select select-seats"
                v-show="!isUserSelectMoreThan10Seats"
              />

              <form v-show="isUserSelectMoreThan10Seats">
                <input
                  v-bind:value="count"
                  @keydown="onChangeInput"
                  @focus="onOpen"
                  @blur="onBlurInput"
                />
              </form>
              <div
                class="discount-percent-container"
                v-show="seatsSelectFocused"
              >
                <discount-detail :showAnnualDiscount="false" />
              </div>
            </div>

            <template v-else>
              <input v-model="count" disabled />
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { TRIAL_BUNDLE_ID } from './constants';
import updateOrderMixin from './mixins/updateOrder';
import SelectUserTable from '@/components/tenantAdmin/components/UserSelectTable/UserSelectTable.vue';
import userSubscriptionMixin from '@/components/tenantAdmin/mixins/userSubscription';
import debounce from '@/shared/utils/debounce';
import DiscountDetail from '@/components/tenantAdmin/components/DiscountDetail/DiscountDetail.vue';

export default {
  name: 'seatsForm',
  props: ['isModifying'],
  components: { SelectUserTable, DiscountDetail },
  inject: ['getEstimatedSubscription', 'setIsUpdateSubscriptionDisabled'],
  data() {
    return {
      estimatedSubscription: {},
      count: 1,
      isUserSelectMoreThan10Seats: false,
      seatsSelectFocused: false,
      isAccountsListBiggerThanOwnedSeatsNumber: false,
      tooltip: `Since the number of seats you currently have is smaller than the total number of users you have. <br/> You will have to disable some of your users. <br/> Those users won't be able to access our system until you buy more seats and re-enable them`
    };
  },
  mixins: [updateOrderMixin, userSubscriptionMixin],
  computed: {
    ...mapGetters(['principal']),
    ...mapGetters('fastspring', [
      'selectedBundle',
      'extraSeatCount',
      'isSubscribed',
      'isPaymentCompleted',
      'subscription',
      'totalPrice',
      'userCount',
      'isCancelingSubscription'
    ]),
    instruction() {
      const { pid, subscription } = this.selectedBundle || {};
      const { instructions } = subscription || {};
      const [instruction] = instructions || [];
      const { periodEndDate } = instruction || {};

      const isUserSelectedTrialBundle = pid === TRIAL_BUNDLE_ID;
      const nextChargeValue =
        this.totalPrice ||
        (this.estimatedSubscription.proposedPlan &&
          this.estimatedSubscription.proposedPlan.subscriptionTotalDisplay);

      if (isUserSelectedTrialBundle) {
        if (!periodEndDate) return '';

        return `Your Trial Plan will expires on: ${periodEndDate}`;
      }

      if (!subscription || !nextChargeValue || !subscription.nextChargeDate) {
        return '';
      }

      return `Next charge: ${nextChargeValue} on ${subscription.nextChargeDate}`;
    },
    selectedBundleSeatCount() {
      return +((this.selectedBundle || {}).attributes || {}).seatCount;
    },
    extraSeat() {
      return (this.selectedBundle || {}).extraSeat;
    },
    isUserUpgradingSubscription() {
      return (
        this.subscription &&
        this.selectedBundle.pid !== TRIAL_BUNDLE_ID &&
        !this.subscription.fastspringSubscriptionId
      );
    },
    isUserUpdatingSubscriptionDetails() {
      return (
        this.subscription &&
        this.selectedBundle.pid !== TRIAL_BUNDLE_ID &&
        this.subscription.fastspringSubscriptionId
      );
    }
  },
  watch: {
    count(newValue) {
      this.isAccountsListBiggerThanOwnedSeatsNumber = this.userCount > newValue;
      this.setIsUpdateSubscriptionDisabled({
        value: this.isAccountsListBiggerThanOwnedSeatsNumber,
        tooltip: this.tooltip
      });
    },
    userCount(newValue) {
      this.isAccountsListBiggerThanOwnedSeatsNumber = newValue > this.count;
      this.setIsUpdateSubscriptionDisabled({
        value: this.isAccountsListBiggerThanOwnedSeatsNumber,
        tooltip: this.tooltip
      });
    }
  },
  async mounted() {
    await this.getUserList();
    await this.getSubscriptionEstimation();
    this.count = this.selectedBundleSeatCount + this.extraSeatCount;
    if (this.count >= 10) this.isUserSelectMoreThan10Seats = true;
  },
  methods: {
    ...mapMutations('fastspring', ['SET_EXTRA_SEAT_COUNT']),
    ...mapActions('fastspring', ['updateCart', 'getAccountListOfUser']),
    onChange(seats) {
      if (seats === '10+') {
        this.count = 11;
        this.isUserSelectMoreThan10Seats = true;
      } else {
        this.count = Number(seats);
      }

      if (seats < 10) {
        this.isUserSelectMoreThan10Seats = false;
      }

      debounce(this.update, 500)();
    },
    onChangeInput(event) {
      if (isNaN(event.key) && event.key !== 'Backspace') {
        event.preventDefault();
      }
    },
    onBlurInput(event) {
      this.onClose();

      const seats = Number(event.target.value.trim());
      if (seats === this.count) return;
      this.onChange(seats);
    },
    onOpen() {
      this.seatsSelectFocused = true;
    },
    onClose() {
      this.seatsSelectFocused = false;
    },
    async update() {
      this.SET_EXTRA_SEAT_COUNT(this.count - this.selectedBundleSeatCount);

      await this.getUserList();
      await this.getSubscriptionEstimation();
    },
    async getUserList() {
      await this.getAccountListOfUser();
    },
    async getSubscriptionEstimation() {
      try {
        const cart = await this.updateCart({ skipUpdateSession: true });
        const estimatedSubscription = await this.updateOrder(cart);

        this.getEstimatedSubscription(estimatedSubscription);
        this.estimatedSubscription = estimatedSubscription;
      } catch (error) {
        console.error('Update Cart Error:', error);
      }
    },
    goTo(url) {
      this.$router.push(url);
    }
  }
};
</script>

<style lang="scss" scoped>
.current-subscription {
  &__column-titles,
  &__body {
    display: flex;
    align-items: flex-start;
  }

  &__column-title,
  &__column {
    flex: 1;
  }

  &__column-titles {
    padding: 0 16px;
  }

  &__column-title {
    color: #848d96;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  &__column {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;

    &--grayed {
      color: rgba(255, 255, 255, 0.5);
      font-size: 16px;
    }

    &--green {
      color: #34a54b;
      font-weight: 600;
    }
  }
}

.seats-form-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
}

.current-subscription__body,
.seats-form-body {
  border-radius: 8px;
  border: 1px solid rgba(230, 230, 230, 0.2);
  background: #091b2d;
  padding: 20px 16px;
  margin-top: 16px;
}

.number-of-seats-form {
  display: flex;
}

.instruction-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.instruction-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}

.instruction-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.5);
}

.seat-count-field {
  display: flex;
  align-items: center;
  margin-left: 36px;

  input {
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    background: #091b2d;
    padding: 10px 12px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: 21px;
    flex: 0 0 74px;
    width: 80px;
    height: 45px;
  }

  button {
    background: none;
    border: none;
  }
}

.extra-seats-form {
  margin-top: 20px;
}

.back-button {
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    text-decoration: underline;
  }

  img {
    padding: 6px;
    border: 1px solid #fff;
    border-radius: 8px;
    margin-right: 8px;
  }
}

.seat-select-container {
  position: relative;

  .discount-percent-container {
    position: absolute;
    width: 310px;
    z-index: 1;
    left: 110%;
    top: 0px;
  }
}
</style>
