<template>
  <div class="bundles-page">
    <payment-header />

    <div v-if="showCheckout" class="body container">
      <order-form
        class="form-container"
        v-show="!isPaymentCompleted && !isOrderPreview"
        :isModifying="isModifying"
        @onSetIsModifying="onSetIsModifying"
      />
      <order-preview class="form-container payment" v-show="isOrderPreview">
      </order-preview>
      <payment-form
        class="form-container"
        v-show="!isOrderPreview"
        :estimatedSubscription="estimatedSubscription"
        :isShowingUpdateSubscriptionBtn="isModifying"
        :isUpdateSubscriptionDisabled="isUpdateSubscriptionDisabled"
      />
    </div>

    <subscription-term-and-condition
      v-if="subscription && !subscription.agreedTerms"
      @click-accept="handleAcceptTermsClick"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import OrderForm from '@/components/tenantAdmin/orderForm.vue';
import PaymentForm from '@/components/tenantAdmin/paymentForm.vue';
import OrderPreview from '@/components/tenantAdmin/orderPreview.vue';
import SubscriptionTermAndCondition from '@/components/tenantAdmin/subscriptionTermAndCondition.vue';
import PaymentHeader from '@/components/tenantAdmin/paymentHeader.vue';
import getUserSubscriptionMixin from './mixins/getUserSubscription';
import SLBMixin from '@/shared/mixins/slb';

export default {
  mixins: [getUserSubscriptionMixin, SLBMixin],
  components: {
    PaymentForm,
    OrderPreview,
    OrderForm,
    SubscriptionTermAndCondition,
    PaymentHeader
  },
  data() {
    return {
      showCheckout: false,
      isInitialized: false,
      estimatedSubscription: null,
      isModifying: true,
      isUpdateSubscriptionDisabled: {
        value: false,
        tooltip: ''
      }
    };
  },
  provide() {
    return {
      getEstimatedSubscription: this.getEstimatedSubscription,
      setIsUpdateSubscriptionDisabled: this.onSetIsUpdateSubscriptionDisabled
    };
  },
  computed: {
    ...mapGetters('fastspring', [
      'selectedBundle',
      'subscription',
      'isSubscribed',
      'isPaymentCompleted',
      'isOrderPreview'
    ]),
    isAgreedTerms() {
      return this.subscription && this.subscription.agreedTerms;
    }
  },
  async mounted() {
    if (!this.$route.query.bundle) {
      await this.getSubscriptionInfo();
    }

    if (!this.selectedBundle) {
      const bundle = { pid: this.subscription.productId };
      this.SET_SELECTED_BUNDLE(bundle);
    }

    if (this.isAgreedTerms) {
      this.initFastSpring();
      document.body.style.overflow = 'auto';
    }
  },
  methods: {
    ...mapMutations('fastspring', [
      'INITIALIZE',
      'DATA_CHANGED',
      'SET_SELECTED_BUNDLE',
      'SET_IS_LOADING',
      'INITIALIZE_SELECTED_COUNTRIES',
      'INITIALIZE_SELECTED_BUNDLE'
    ]),
    ...mapActions('fastspring', [
      'updateCart',
      'onPaymentSuccess',
      'agreeSubscriptionTerms'
    ]),
    handleAcceptTermsClick() {
      this.agreeSubscriptionTerms().then(() => {
        this.initFastSpring();
        document.body.style.overflow = 'auto';
      });
    },
    initFastSpring() {
      const scriptId = 'fsc-api';
      const existingScript = document.getElementById(scriptId);

      if (existingScript) {
        existingScript.remove();
      }

      const fastSpringStorefront = process.env.VUE_APP_FASTSPRINGSTOREFRONT;
      const script = document.createElement('script');

      window.fsDataCallBack = this.DATA_CHANGED;

      window.fsBeforeRequestCallback = () => {
        this.SET_IS_LOADING(true);
      };

      window.fsAfterRequestCallback = () => {
        if (this.isSubscribed && !this.isInitialized) {
          this.isInitialized = true;

          this.INITIALIZE_SELECTED_BUNDLE();
          this.INITIALIZE_SELECTED_COUNTRIES();
          this.updateCart().then(() => {
            this.INITIALIZE_SELECTED_COUNTRIES();
            this.updateCart().then(() => {
              this.INITIALIZE_SELECTED_COUNTRIES();
              this.showCheckout = true;
              this.SET_IS_LOADING(false);
            });
          });
        } else if (this.selectedBundle && !this.isInitialized) {
          this.isInitialized = true;

          this.INITIALIZE_SELECTED_BUNDLE();
          this.updateCart().then(() => {
            this.isInitialized = true;
            this.showCheckout = true;
            this.SET_IS_LOADING(false);
          });
        } else {
          this.isInitialized = true;
          this.SET_IS_LOADING(false);
        }
      };

      window.fsPopupWebhookReceived = this.onPaymentSuccess;

      script.type = 'text/javascript';
      script.id = scriptId;
      script.src =
        'https://sbl.onfastspring.com/sbl/0.9.2/fastspring-builder.min.js';
      script.dataset.storefront = fastSpringStorefront;
      script.setAttribute('data-data-callback', 'fsDataCallBack');
      script.setAttribute(
        'data-before-requests-callback',
        'fsBeforeRequestCallback'
      );
      script.setAttribute(
        'data-after-requests-callback',
        'fsAfterRequestCallback'
      );
      script.setAttribute(
        'data-popup-webhook-received',
        'fsPopupWebhookReceived'
      );
      script.addEventListener('load', () => this.INITIALIZE(window.fastspring));

      document.head.appendChild(script);
    },
    getEstimatedSubscription(value) {
      this.estimatedSubscription = value;
    },
    onSetIsModifying(value) {
      this.isModifying = value;
    },
    onSetIsUpdateSubscriptionDisabled(value) {
      this.isUpdateSubscriptionDisabled = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #13151a;
  padding: 20px 0;

  &__img {
    width: 204px;
    height: 72px;
  }

  &__button {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 14px 22px 14px 24px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    height: 48px;
  }
}

.bundles-page {
  max-height: 100vh;
  overflow: auto;
}

.payment {
  width: 500px;
}
.body {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  margin-top: 72px;
  color: #fff;
}
</style>
