<template>
  <div>
    <tr :class="className">
      <td colspan="6" style="width: 100%">
        <p>{{ itemName }} x {{ product.quantity }}</p>
      </td>
    </tr>
    <tr :class="className">
      <td style="width: 25%">Product Price</td>
      <td style="width: 25%">Quantity</td>
      <td style="width: 50%">Total Price</td>
    </tr>
    <tr :class="className">
      <td class="summary__value" style="width: 25%">
        {{ product.price | displayPrice(isRefund, product.currency) }}
      </td>
      <td class="summary__value" style="width: 25%">
        {{ product.quantity | displayPrice(isRefund) }}
      </td>
      <td class="summary__value summary__value_final" style="width: 50%">
        <span>
          {{
            (product.price * product.quantity)
              | displayPrice(isRefund, product.currency)
          }}
          <span v-if="product.product.includes('-bundle') && !isBundle"
            >(Included in bundle price)</span
          >
          <br />
        </span>
      </td>
    </tr>
  </div>
</template>

<script>
import { displayPrice } from '@/components/tenantAdmin/filters/displayPrice';

export default {
  name: 'subscriptionProductSummary',
  props: [
    'itemName',
    'proposedProduct',
    'currentProduct',
    'className',
    'isBundle'
  ],
  filters: {
    displayPrice
  },
  computed: {
    paidProductQuantity() {
      return this.currentProduct ? this.currentProduct.quantity : 0;
    },
    isRefund() {
      return this.product.quantity - this.paidProductQuantity < 0;
    },
    product() {
      return {
        ...this.proposedProduct,
        currency: this.proposedProduct.priceDisplay.replace(
          this.proposedProduct.priceDisplay.replace(/[^\d,.]/g, ''),
          ''
        )
      };
    }
  }
};
</script>

<style lang="scss" scoped>
tr {
  width: 100%;
  display: flex;
  justify-content: center;
}

.row {
  &--even {
    background-color: #091b2d;
  }

  &--odd {
    background-color: #041322;
  }
}

td {
  padding: 0 10px;
  width: 25%;

  &.summary__value {
    padding: 0px 10px 10px;

    &_final {
      color: #34a54b;
    }

    .summary__value_old {
      text-decoration: line-through;
      color: #ffffff;
    }
  }
}

p {
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  margin: 10px 0 6px;
}
</style>
