<template>
  <div class="region-item">
    <img
      v-if="item.image"
      class="selected-item__image"
      :src="item.image"
      alt=""
    />

    <div
      v-else
      class="selected-item__image default"
    />

    <span class="selected-item__text">{{ item.text }}</span>
    <span class="selected-item__price">
      {{ item.price }}
    </span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style
  lang="scss"
  scoped
>
.region-item {
  height: 48px;
  padding: 0 18px;
  display: flex;
  align-items: center;

  &:nth-child(odd) {
    background: #041322;
  }

  &:nth-child(even) {
    background: #091B2D;
  }
}

.selected-item {
  &__image {
    width: 26.6px;
    height: 20px;
    margin-right: 10px;

    &.default {
      background-color: #eee;
    }
  }

  &__text {
    flex: 1;
    color: #FFF;
    font-size: 16px;
    line-height: 19px;
  }

  &__price {
    margin: 0 12px;
    color: #8BD385;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  &__button {
    background: none;
    border: none;
    outline: none;
  }

  &__icon {
    cursor: pointer;
  }
}
</style>
