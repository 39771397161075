<template>
	<div :class="{ splash: true, fadeout: !isLoading }">
		<img width="135" src="@/assets/img/logo-animated.svg" />
	</div>
</template>

<script>
export default {
	props: ["isLoading"]
};
</script>