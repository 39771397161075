export default class Chart {

    color = "#00FF00";
    x = 0;
    y = 0;
    xi = 0;
    yi = 0;
    svg = null;
    svgNS = null;
    xCenter = 0;
    yCenter = 0;
    stroke = 0;

    constructor(svg, svgNS, xCenter, yCenter, color, stroke) {

        this.svg = svg;
        this.svgNS = svgNS;
        this.xCenter = xCenter;
        this.yCenter = yCenter;
        this.color = color;
        this.stroke = stroke;
    }

    drawLine(key, xSource, ySource, xDest, yDest) {

        let line = document.createElementNS(this.svgNS, "line");
        this.x = this.xCenter + xSource;
        this.y = this.yCenter + ySource;
        this.xi = this.x + xDest
        this.yi = this.y + yDest;

        line.setAttribute("id", key);
        line.setAttribute("x1", this.x);
        line.setAttribute("y1", this.y);
        line.setAttribute("x2", this.xi);
        line.setAttribute("y2", this.yi);
        line.setAttribute("stroke", this.color);
        line.setAttribute("stroke-width", this.stroke);
        line.setAttribute("stroke-linecap", "round");

        this.svg.appendChild(line);
    }

    drawText(key, val, modX, modY, size, color, dominantBaseline, textAnchor) {

        let text = document.createElementNS(this.svgNS, "text");
        text.setAttribute("id", key)
        text.setAttribute("fill", color);
        text.setAttribute("x", this.xCenter + modX);
        text.setAttribute("y", this.yCenter + modY);
        text.setAttribute("font-size", size);
        text.setAttribute("dominant-baseline", dominantBaseline);
        text.setAttribute("text-anchor", textAnchor);

        let node = document.createTextNode(val);
        text.appendChild(node);
        this.svg.appendChild(text);
    }

}