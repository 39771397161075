<template>
	<li class="custom k-item d-flex align-items-center custom primary" @click.stop="handleClick">
		<input id="dataItem" type="checkbox" @click.stop="handleClick" :checked="selected" class="k-checkbox k-checkbox-md k-rounded-md" />
		<label>{{ dataItem }}</label>
	</li>
</template>
<script>
export default {
	emits: {
		click: null
	},
	props: {
		id: String,
		index: Number,
		dataItem: [Object, String, Number],
		textField: String,
		focused: Boolean,
		selected: Boolean
	},
	methods: {
		handleClick: function (e) {
			this.$emit("click", e);
		}
	}
};
</script>
