<template>
  <div class="layout layout--public">
    <div class="layout__body">
      <div class="subscription-tnc">
        <div class="subscription-tnc__title">Terms of Service</div>

        <div class="subscription-tnc__content custom-scrollbar">
          <div v-html="htmlContent" />

          <div class="subscription-tnc__footer">
            <k-button
              class="subscription-tnc__accept-btn"
              @click="handleAcceptClick"
              theme-color="primary"
              v-if="!!htmlContent"
            >
              Accept
            </k-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      htmlContent: ''
    };
  },
  mounted() {
    this.getTermAndCondition();
  },
  methods: {
    ...mapActions('fastspring', ['agreeSubscriptionTerms']),
    getTermAndCondition() {
      axios
        .post('/subscription-tnc', {
          headers: {
            Authorization: `Bearer ${this.$store.state.principal.idToken}`
          }
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.htmlContent = response.data.content;
          }
        });
    },
    handleAcceptClick() {
      this.$emit('click-accept');
    }
  }
};
</script>

<style scoped lang="scss">
.subscription-tnc {
  display: flex;
  flex-direction: column;
  padding: 56px 0;
  background-color: #13283d;
  border-radius: 8px;
  width: 704px;
  min-height: 344px;
  max-height: 758px;
  margin: 0 auto;
  overflow: hidden;
  color: #fafafa;
  line-height: 150%;

  &__title {
    font-size: 26px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
    text-align: left;
    padding: 0 44px;
  }

  &__content {
    overflow: auto;
    flex: 1;
    padding: 0 44px;
  }

  &__footer {
    margin-top: 32px;
  }

  &__accept-btn {
    height: 55px;
    width: 100%;
    background: #ea8f36;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }
}
</style>
