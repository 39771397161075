import { mapGetters } from 'vuex';
import {
  checkIsUserSubscriptionExpired,
  checkIsUserUsingTrialSubscription,
  checkIsUserPaidForSubscription,
  checkIsAnnuallySubscription,
  checkIsTrialSubscriptionAboutToEnd
} from '@/shared/utils/subscription';

export default {
  data: {},
  computed: {
    ...mapGetters('fastspring', ['selectedBundle', 'subscription']),

    isUserUsingTrialSubscription() {
      return checkIsUserUsingTrialSubscription(this.subscription);
    },
    isTrialSubscriptionAboutToEnd() {
      return checkIsTrialSubscriptionAboutToEnd(this.subscription);
    },
    isTrialSubscriptionExpired() {
      return checkIsUserSubscriptionExpired(this.subscription);
    },
    isPaymentOverdue() {
      return !checkIsUserPaidForSubscription(this.subscription);
    },
    isUserUsingAnnuallySubscription() {
      return checkIsAnnuallySubscription(this.selectedBundle);
    }
  }
};
