<template>
  <div :class="{ 'discount-detail': showAnnualDiscount }">
    <table class="discount-detail_table">
      <tbody>
        <tr class="row--even">
          <th style="width: 30%">Seat(s)</th>
          <th style="width: 70%">
            Discount % <br />
            (On total price)
          </th>
        </tr>
        <tr
          v-for="(discount, seat, index) in displayDiscountPercents"
          :key="index"
          :class="index % 2 === 0 ? 'row--odd' : 'row--even'"
        >
          <td style="width: 30%">
            {{ Number(seat) + 1 }}<span v-if="discount.range">+</span>
          </td>
          <td style="width: 70%">{{ discount.value }}%</td>
        </tr>
      </tbody>
    </table>

    <table class="discount-detail_table" v-if="showAnnualDiscount">
      <tbody>
        <tr class="row--even">
          <th style="width: 30%">Subscription</th>
          <th style="width: 70%">
            Discount % <br />
            (On total price)
          </th>
        </tr>
        <tr class="row--odd">
          <td style="width: 30%">Monthly</td>
          <td style="width: 70%">Seats discount</td>
        </tr>
        <tr class="row--even">
          <td style="width: 30%">Annually</td>
          <td style="width: 70%">Seats discount + extra 20%</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  DISCOUNT_PERCENTS,
  ANNUAL_SUBSCRIPTION_DISCOUNT_PERCENTS
} from '@/shared';

export default {
  name: 'discountDetail',
  props: {
    showAnnualDiscount: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.DISCOUNT_PERCENTS = DISCOUNT_PERCENTS;
    this.ANNUAL_SUBSCRIPTION_DISCOUNT_PERCENTS =
      ANNUAL_SUBSCRIPTION_DISCOUNT_PERCENTS;
  },
  computed: {
    displayDiscountPercents() {
      const displayDiscountPercents = {};

      Object.entries(this.DISCOUNT_PERCENTS).filter(([seat, discount]) => {
        if (!discount.hide) displayDiscountPercents[seat] = discount;
      });

      return displayDiscountPercents;
    }
  }
};
</script>

<style lang="scss" scoped>
.discount-detail {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  align-items: baseline;

  &_table {
    margin-top: 10px;
    min-width: 0;
    width: auto;
    border: 1px solid #4b5c6e;

    tbody {
      display: table-row-group;

      th,
      td {
        padding: 5px 10px;
      }

      .row {
        &--even {
          background-color: #091b2d;
        }

        &--odd {
          background-color: #041322;
        }
      }
    }
  }
}
</style>
