export const TRIAL_BUNDLE_ID = 'transparently-trial-bundle';

export const TRIAL_BUNDLE = {
  display: 'Trial',
  seatCount: 1,
  regionCount: 1,
  priceTotal: '$0.00'
};

export const BLOG_RECEIVE = [
  {
    label: 'Instant',
    value: 'instant'
  },
  {
    label: 'Daily',
    value: 'daily'
  },
  {
    label: 'Weekly',
    value: 'weekly'
  },
  {
    label: 'Monthly',
    value: 'monthly'
  }
];

export const MARKETABLE_CONTACT_STATUS = [
  {
    label: 'Marketing contact',
    value: true
  },
  {
    label: 'Non-marketing contact',
    value: false
  }
];

export const DEFAULT_BLOG_RECEIVE = 'instant';
export const DEFAULT_MARKETABLE_CONTACT_STATUS = true;

export const MAX_SEARCH_RECORDS = 200;

export const TRIAL_DAYS = 14;
export const DAYS_BEFORE_TRIAL_END_TO_SHOW_NOTICE = 3;
