<template>
  <div>
    <div v-if="isReportFlag == false">
      <div class="row dashboard__panel">
        <div class="d-flex align-items-center dashboard__panel--header">
          <span
            class="col-10 dashboard__panel--header--title"
            v-if="isSectorRisk"
            >Sector Risk Distribution</span
          >
          <span class="col-10 dashboard__panel--header--title" v-else
            >Risk Distribution</span
          >

          <div class="col-2 d-flex justify-content-end align-items-center">
            <span class="dashboard__panel--header--title pe-1">{{ year }}</span>

            <span
              class="d-flex align-center k-link px-1"
              v-if="isSectorRisk"
              @click="showSectorRiskPopup"
              ><icon-info></icon-info
            ></span>
            <span
              class="d-flex align-center k-link px-1"
              v-else
              @click="showRiskPopup"
              ><icon-info></icon-info
            ></span>
          </div>
        </div>
        <div
          v-if="
            chart.ticker != null &&
            chart.chart.x !== undefined &&
            chart.chart.y !== undefined
          "
        >
          <div class="row px-2 mx-0" style="height: 50px">
            <div class="dashboard--xsmall px-0" v-if="isSectorRisk">
              <icon-circle-green /><span class="text-uppercase ps-2">{{
                chart.ticker.sector_name
              }}</span>
            </div>
            <div class="dashboard--xsmall px-0" v-else>
              <icon-circle-gray2 /><span class="text-uppercase ps-2">{{
                chart.ticker.region_name
              }}</span>
            </div>

            <div class="dashboard--xsmall px-0" v-if="isSectorRisk">
              <icon-circle-red /><span class="text-uppercase ps-2">{{
                chart.ticker.name
              }}</span>
            </div>
            <div class="dashboard--xsmall px-0" v-else>
              <icon-circle-red /><span class="text-uppercase ps-2">{{
                chart.ticker.name
              }}</span>
            </div>
          </div>
          <div
            class="row dashboard__row px-0 mx-0"
            v-if="
              (riskData != null || sectorRiskData != null) && category != null
            "
          >
            <div class="ms-1 ps-0">
              <chart-custom
                v-if="loadChart == true"
                :data="chartData"
                :color="chartColor"
                :size="chartSize"
                :point="redPoint"
                :pointerLine="pointerLine"
                :category="category"
                :fontSize="''"
              />
            </div>
            <div
              class="row dashboard--stack-top justify-content-center text-white"
              v-if="isSectorRisk"
            >
              {{
                chart.ticker.rankPercentWithinSector == null
                  ? 'N/A'
                  : Math.round(chart.ticker.rankPercentWithinSector * 100)
              }}
              percentile
            </div>
            <div
              class="dashboard--stack-top d-flex justify-content-center text-white"
              v-else
            >
              {{
                chart.ticker.rankPercent == null
                  ? 'N/A'
                  : Math.round(chart.ticker.rankPercent * 100)
              }}
              percentile
            </div>
          </div>

          <div
            class="dashboard__popup dashboard__sector-risk-popup dashboard__panel--overflow"
            v-if="isSectorRisk && showSRP"
            v-click-outside="onClickOutside"
          >
            <div class="row pb-4">
              <div class="col-1 pt-2"><icon-info-popup></icon-info-popup></div>
              <div class="col-9 header d-flex justify-content-start pt-1">
                Sector Risk Score Distribution
              </div>
              <div
                class="col-2 d-flex justify-content-end"
                @click="hideSectorRiskPopup"
              >
                <icon-close2></icon-close2>
              </div>
            </div>
            <div class="row">
              <div class="col-12 gray">
                {{ popupsMap[POPUP_ID.distributionSector] }}
              </div>
            </div>
          </div>
          <div
            class="dashboard__popup dashboard__risk-popup dashboard__panel--overflow"
            v-if="!isSectorRisk && showRP"
            v-click-outside="onClickOutside"
          >
            <div class="row pb-4">
              <div class="col-1 pt-2"><icon-info-popup></icon-info-popup></div>
              <div class="col-9 header d-flex justify-content-start pt-1">
                Risk Score Distribution
              </div>
              <div
                class="col-2 d-flex justify-content-end"
                @click="hideRiskPopup"
              >
                <icon-close2></icon-close2>
              </div>
            </div>
            <div class="row">
              <div class="col-12 gray">
                {{ popupsMap[POPUP_ID.distributionTotal] }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="row align-items-center justify-content-center"
          style="height: 310px"
        >
          No data available for this year
        </div>
      </div>
    </div>
    <div v-if="isReportFlag == true">
      <div
        class="d-flex dashboard--small justify-content-between report-diagram-head px-1 mb-1"
      >
        <span v-if="isSectorRisk">Sector Risk Distribution</span>
        <span v-else>Risk Distribution</span>
        <span>{{ year }}</span>
      </div>
      <div v-if="chart.ticker != null">
        <div class="px-2 mb-1" v-if="isSectorRisk">
          <div class="dashboard--xxsmall">
            <icon-circle-green />
            <span class="text-uppercase ps-2">{{
              chart.ticker.sector_name
            }}</span>
          </div>
          <div class="dashboard--xxsmall">
            <icon-circle-red />
            <span class="text-uppercase ps-2">{{ chart.ticker.name }}</span>
          </div>
        </div>
        <div class="px-2 mb-1" v-else>
          <div class="dashboard--xxsmall">
            <icon-circle-gray2 /><span class="text-uppercase ps-2">{{
              chart.ticker.region_name
            }}</span>
          </div>
          <div class="dashboard--xxsmall">
            <icon-circle-red /><span class="text-uppercase ps-2">{{
              chart.ticker.name
            }}</span>
          </div>
        </div>
        <div class="row pb-2">
          <chart-custom
            v-if="loadChart == true"
            :data="chartData"
            :color="chartColor"
            :size="chartSize"
            :point="redPoint"
            :pointerLine="pointerLine"
            :category="category"
            :fontSize="'8px'"
          />
        </div>
      </div>
      <div
        v-else
        class="row align-items-center justify-content-center"
        style="height: 170px"
      >
        No data available for this year
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import chartCustom from './chartDistribution.vue';
import { POPUP_ID } from '@/shared';
export default {
  props: ['chart', 'popupsMap', 'isSectorRisk', 'isReportFlag'],
  components: {
    chartCustom
  },
  watch: {
    isSectorRisk: {
      handler: function (val) {
        this.type = val;
      }
    },
    chart: {
      handler: function (val) {
        if (val != null) {
          this.chartData = val.chart.y || [];
          this.category = val.category || [];
          this.redPoint = val.point || 0;
          this.year = val.year || 0;

          if (this.isSectorRisk) {
            this.chartColor = '#68B063';
          } else if (!this.isSectorRisk) {
            this.chartColor = '#BBBBBB';
          }

          if (this.isReportFlag) {
            this.chartSize = 'auto';
            this.pointerLine = 100;
          }

          this.loadChart = true;
        }
      },
      immediate: true,
      deep: true
      // loaded: false,
    }
  },
  data() {
    return {
      loadChart: false,
      smoothLine: 'smooth',
      showRP: false,
      showSRP: false,
      category: [],
      riskData: [],
      sectorRiskData: [],
      chartData: [],
      chartColor: 'white',
      chartSize: '260px',
      pointerLine: 180,
      redPoint: 0,
      year: 0,
      type: ''
    };
  },
  computed: {
    POPUP_ID() {
      return POPUP_ID;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isReportFlag == true) {
        this.$emit('rendered');
      }
    });
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside() {
      this.hideRiskPopup();
      this.hideSectorRiskPopup();
    },
    showRiskPopup() {
      if (!this.showRP) {
        this.showRP = true;
      }
    },
    hideRiskPopup() {
      if (this.showRP) {
        this.showRP = false;
      }
    },
    showSectorRiskPopup() {
      if (!this.showSRP) {
        this.showSRP = true;
      }
    },
    hideSectorRiskPopup() {
      if (this.showSRP) {
        this.showSRP = false;
      }
    }
  }
};
</script>
