<template>
  <div class="header">
    <div class="container d-flex justify-content-between">
      <icon-logo-header class="header-img" />

      <div class="header-buttons">
        <k-button
          class="header-button me-3"
          @click="$router.push('/dashboard')"
        >
          Dashboard
        </k-button>

        <k-button class="header-button me-3" @click="showLogoutDialog = true">
          <icon-logout />
          <span>Logout</span>
        </k-button>
      </div>
    </div>

    <k-dialog
      v-if="showLogoutDialog"
      :title="' '"
      @close="showLogoutDialog = false"
    >
      <div class="row">
        <div class="col-12">
          <p class="title d-flex justify-content-center">
            Log out of Transparently.AI
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-2 d-flex justify-content-center">
          <k-button
            class="me-2"
            fill-mode="outline"
            @click="showLogoutDialog = false"
          >
            Cancel
          </k-button>

          <k-button theme-color="primary" @click="logout"> Yes </k-button>
        </div>
      </div>
    </k-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLogoutDialog: false
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #13151a;
  padding: 20px 0;
  margin-bottom: 56px;
}

.header-img {
  width: 204px;
  height: 72px;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.header-button {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 14px 22px 14px 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  height: 48px;

  &--small {
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    padding: 10px 14px;

    svg {
      max-width: 20px;
    }
  }
}
</style>
